import React from 'react';
import Timestamp from 'react-timestamp'
import Tag from '../components/Tag.component';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const BlogCardBig = ({variant = "vertical", blogData, className=""}) => {
    const navigate = useNavigate();
    
    // Animation for the card hover state
    const hoverTransition = { type: "spring", stiffness: 300, damping: 20 };
    
    return (
        <motion.div 
            whileHover={variant === "vertical" ? { y: -10 } : { x: -5 }}
            transition={hoverTransition}
            className={`
                ${className} grid ${variant === "horizontal" ? "grid-cols-2" : "grid-cols-1"}  
                gap-4 h-fit group bg-white rounded-xl shadow-sm hover:shadow-xl 
                transition-all duration-300 overflow-hidden border border-gray-100
                xl:gap-6
            `}
        >
            {/* Image-container */}
            <div className={`${variant === "horizontal"? "h-full" : "w-full"} relative overflow-hidden`}>
                <div className="absolute inset-0 bg-gradient-to-tr from-blue-500/10 to-indigo-600/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10"></div>
                <motion.img
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.4 }}
                    src={blogData.banner}
                    alt={blogData.title}
                    className={`${variant === "horizontal" ? "h-full" : "w-full aspect-video"} object-cover object-center transform transition-transform duration-700`}
                />
                <div className="absolute top-3 left-3 z-20">
                    <Tag name={blogData.tags[0]} onClickHandler={undefined} type="filled" />
                </div>
            </div>
            
            {/* text - container */}
            <div className="flex flex-col gap-4 w-full p-5">
                <h1 className="line-clamp-2 font-extrabold text-2xl text-gray-800 group-hover:text-blue-700 transition-colors duration-300 md:text-3xl">
                    {blogData.title}
                </h1>
                
                <p className="line-clamp-2 text-gray-600 text-base lg:line-clamp-3">
                    {blogData.short_description}
                </p>
                
                <div className="mt-auto pt-4 border-t border-gray-100">
                    <div className="flex items-center gap-2 text-sm text-gray-500">
                        <div className="flex items-center gap-1">
                            <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                            <Timestamp relative date={blogData.created_at} /> 
                        </div>
                        
                        <span className="inline-block w-1 h-1 rounded-full bg-gray-400"></span>
                        
                        <div className="flex items-center gap-1">
                            <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path>
                            </svg>
                            <span>{blogData.read_time} min read</span>
                        </div>
                    </div>
                </div>
                
                <motion.button 
                    className="self-end p-3 bg-blue-50 text-blue-600 rounded-full hover:bg-blue-600 hover:text-white transition-colors duration-300 group-hover:animate-pulse"
                    whileHover={{ scale: 1.1, rotate: -45 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => {
                        navigate("/blogs/" + blogData.id + "/" + blogData.title);
                    }}
                >
                    <FaArrowRight />
                </motion.button>
            </div>
        </motion.div>
    );
};

export default BlogCardBig;
