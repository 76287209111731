import { useEffect } from "react";
import { motion } from "framer-motion";
import GradientText from "../../components/GradientText.component";
import Footer from "../../components/Footer.component";
import GradientContactCard from "../../components/GradientContactCard.component";

// Import Wintellisys logo
import wintellisysLogo from "../../assets/logo/logo-light.png"; // Add this logo to your assets folder

export default function Partners() {
    useEffect(() => {
        document.title = "Partners | QUBITWARE";
        window.scrollTo(0, 0);
    }, []);

    const partners = [
        {
            name: "Wintellisys",
            logo: wintellisysLogo,
            website: "https://wintellisys.com/",
            description: "Wintellisys is a leading provider of innovative IT solutions, specializing in digital transformation, cloud services, and enterprise systems. With a focus on cutting-edge technology, Wintellisys helps businesses optimize their operations and achieve strategic objectives through tailored IT strategies.",
            highlights: [
                "Digital Transformation",
                "Cloud Services & Solutions",
                "Enterprise Systems",
                "IT Consulting"
            ]
        },
        {
          name: "LeadGen Wingman",
          logo: "https://media.licdn.com/dms/image/v2/D560BAQFsWyA4fcnmAA/company-logo_200_200/company-logo_200_200/0/1733258314431?e=2147483647&v=beta&t=sVbZcj8kzhNh02R7HpQUwYeMXetetZMP3LVnTsgiWaI",
          website: "https://leadgenwingman.com/",
          description: "Transforming Lead Generation and Outreach Through Automation. Lead Gen Wingman exists to simplify and enhance lead generation and verification processes for businesses of all sizes. By automating the time-intensive tasks of lead research, validation, and personalized outreach, we empower businesses to focus on growth and building meaningful relationships with their prospects.",
          highlights: [
            "AI-Powered Lead Generation",
            "Lead Scoring & Qualification",
            "Automated Outreach",
            "Personalized Engagement"
          ]
        }
    ];

    // Animation variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { 
            opacity: 1,
            transition: { 
                staggerChildren: 0.2,
                delayChildren: 0.3
            }
        }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: { 
            y: 0, 
            opacity: 1,
            transition: { duration: 0.5 }
        }
    };

    return (
        <div className="flex flex-col">
            {/* Hero section */}
            <section>
                <div className="container mx-auto py-16 grid grid-cols-1
                    2xl:py-32 xl:grid-cols-2
                ">
                    <div className="flex flex-col items-center gap-y-4">
                        <GradientText
                            className="text-5xl font-extrabold py-2
                                md:text-6xl
                                lg:text-7xl
                                xl:text-8xl
                            "
                        >
                            Our Partners
                        </GradientText>
                        <p className="text-xl text-gray-500 mb-12
                            md:text-xl
                            lg:text-2xl
                            text-center
                        ">
                            Empowering businesses through <b>strategic collaborations</b>
                            <br /> with industry leaders to deliver
                            <br /> exceptional solutions and services at <br />
                            <GradientText>QUBITWARE</GradientText>
                        </p>
                    </div>
                    <div className="col-span-1 flex items-center justify-center">
                        <img 
                            src="./service_imgs/2c.png" 
                            alt="Strategic partnerships illustration" 
                            className="w-full max-w-md"
                        />
                    </div>
                </div>
            </section>

            {/* Partners listing section */}
            <section className="bg-neutral-100 py-16 rounded-2xl">
                <div className="container mx-auto">
                    <motion.div 
                        className="text-center mb-16"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                    >
                        <h2 className="text-4xl font-bold mb-4">
                            <GradientText>Featured Partners</GradientText>
                        </h2>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            We collaborate with industry-leading organizations to provide innovative solutions 
                            that address complex business challenges.
                        </p>
                    </motion.div>

                    <motion.div
                        className="grid grid-cols-1 gap-8 md:gap-12"
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                    >
                        {partners.map((partner, index) => (
                            <motion.div 
                                key={index}
                                variants={itemVariants}
                                className="bg-white rounded-2xl shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
                            >
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-6 md:p-8">
                                    <div className="flex flex-col items-center justify-center space-y-4 md:border-r border-gray-200">
                                        <a 
                                            href={partner.website} 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                            className="block relative group"
                                            aria-label={`Visit ${partner.name} website`}
                                        >
                                            <div className="absolute inset-0 bg-cblue opacity-0 group-hover:opacity-10 rounded-lg transition-opacity duration-300"></div>
                                            <img 
                                                src={partner.logo} 
                                                alt={`${partner.name} logo`} 
                                                className="w-48 h-auto object-contain transition-transform duration-300 group-hover:scale-105" 
                                            />
                                        </a>
                                        <a 
                                            href={partner.website} 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                            className="text-cblue hover:text-cgreen transition-colors duration-300 text-lg font-medium"
                                        >
                                            Visit Website
                                        </a>
                                    </div>
                                    
                                    <div className="md:col-span-2 flex flex-col justify-center">
                                        <h3 className="text-2xl font-bold mb-4 text-center md:text-left">{partner.name}</h3>
                                        <p className="text-gray-600 mb-6">{partner.description}</p>
                                        
                                        <div>
                                            <h4 className="font-semibold text-lg mb-2">Core Competencies:</h4>
                                            <div className="flex flex-wrap gap-2">
                                                {partner.highlights.map((highlight, i) => (
                                                    <span 
                                                        key={i} 
                                                        className="bg-blue-50 text-cblue px-3 py-1 rounded-full text-sm"
                                                    >
                                                        {highlight}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </section>

            {/* Collaboration Benefits Section */}
            <section className="py-16">
                <div className="container mx-auto">
                    <div className="text-center mb-12">
                        <h2 className="text-3xl font-bold mb-4">
                            <GradientText>Why Partner With Us?</GradientText>
                        </h2>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            Our partnership program offers numerous benefits to help grow your business
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {[
                            {
                                title: "Expanded Reach",
                                description: "Gain access to new markets and customer segments through our extensive network",
                                icon: "🌐"
                            },
                            {
                                title: "Innovative Solutions",
                                description: "Collaborate on cutting-edge technologies and solutions to address evolving business needs",
                                icon: "💡"
                            },
                            {
                                title: "Co-marketing Opportunities",
                                description: "Joint marketing initiatives to enhance brand visibility and market presence",
                                icon: "📢"
                            },
                            {
                                title: "Technical Support",
                                description: "Access to specialized technical expertise and resources to enhance service delivery",
                                icon: "🛠️"
                            },
                            {
                                title: "Revenue Growth",
                                description: "Create new revenue streams through collaborative service offerings",
                                icon: "📈"
                            },
                            {
                                title: "Knowledge Exchange",
                                description: "Share industry insights and best practices to maintain competitive advantage",
                                icon: "🔄"
                            }
                        ].map((benefit, index) => (
                            <motion.div 
                                key={index}
                                className="bg-white rounded-xl shadow-sm p-6 hover:shadow-md transition-shadow duration-300 flex flex-col items-center text-center"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.1 * index, duration: 0.5 }}
                            >
                                <span className="text-4xl mb-4" aria-hidden="true">{benefit.icon}</span>
                                <h3 className="text-xl font-bold mb-2">{benefit.title}</h3>
                                <p className="text-gray-600">{benefit.description}</p>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Become a Partner CTA */}
            <section className="py-12 lg:py-16">
                <div className="container mx-auto">
                    <GradientContactCard
                        message="Interested in becoming a partner? Let's explore collaboration opportunities together!"
                    />
                </div>
            </section>

            <Footer varient="blue" />
        </div>
    );
}
