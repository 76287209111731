import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { motion } from "framer-motion";

export default function ServiceCard({ title, description, img_url}) {
    return (
        <motion.div 
            whileHover={{ y: -8 }}
            transition={{ type: "spring", stiffness: 300 }}
            className="group bg-white overflow-hidden rounded-xl shadow-sm hover:shadow-xl border border-gray-100 relative flex flex-col h-full transition-all duration-300"
        >
            <div className="w-full relative overflow-hidden bg-gradient-to-r from-blue-50 to-indigo-50">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-indigo-500/10 group-hover:opacity-0 transition-opacity duration-300"></div>
                <img 
                    className="w-full object-contain object-center h-48 group-hover:scale-105 transition-transform duration-500" 
                    src={img_url} 
                    alt={title}
                    // onError={(e) => {
                    //     e.target.src = "";
                    // }}
                />
            </div>
            
            <div className="flex flex-col justify-start items-start gap-2 text-left p-6 flex-grow">
                <div className="w-12 h-1 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-full mb-2"></div>
                <h3 className="font-bold text-xl text-gray-800 group-hover:text-blue-700 transition-colors duration-300">
                    {title}
                </h3>
                <p className="text-base text-gray-600 flex-grow mb-4">
                    {description}
                </p>
                <Link 
                    to={`/services/${title}`} 
                    className="flex items-center gap-2 text-blue-600 font-semibold group-hover:text-blue-800 transition-colors duration-300"
                >
                    <span>Learn more</span>
                    <span className="bg-blue-100 p-2 rounded-full transform group-hover:translate-x-1 transition-transform duration-300">
                        <FaArrowRight className="text-blue-600" size={14} />
                    </span>
                </Link>
            </div>
            
            <div className="absolute top-3 right-3 bg-white/80 backdrop-blur-sm p-2 rounded-full shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="w-2 h-2 rounded-full bg-blue-600 animate-pulse"></div>
            </div>
        </motion.div>
    );
}
