import { useLoaderData, useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import { useEffect, useRef, useState } from "react";
import Timestamp from "react-timestamp";
import Footer from "../components/Footer.component";
import Tag from "../components/Tag.component";
import { motion } from "framer-motion";
import { FaShare, FaTwitter, FaLinkedin, FaFacebook, FaArrowLeft, FaBookmark, FaRegBookmark, FaRegThumbsUp, FaThumbsUp } from "react-icons/fa";
import GradientText from "../components/GradientText.component";
import BlogCard from "../components/BlogCard.component";
import { FaMoon, FaSun } from "react-icons/fa6";

export const getBlogLoader = async ({params}) => {
    const blogData = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blog/${params.blog_id}/`)
        .then(res => res.data)
        .catch(err => {
            console.log(err);
            return null;
        });
    
    // Also fetch related blogs based on tags
    let relatedBlogs = [];
    if (blogData && blogData.tags && blogData.tags.length > 0) {
        relatedBlogs = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blog/tag/${blogData.tags[0]}/3/`)
            .then(res => res.data.filter(blog => blog.id !== blogData.id).slice(0, 3))
            .catch(err => {
                console.log(err);
                return [];
            });
    }
    
    return { blogData, relatedBlogs };
}

export const stringToHTML = (str) => {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return doc.body.innerHTML;
};

const BlogPost = () => {
    const { blogData, relatedBlogs } = useLoaderData();
    const navigate = useNavigate();
    const { 
        content, 
        title, 
        banner, 
        short_description, 
        author, 
        read_time, 
        created_at, 
        tags,
        id
    } = blogData;
    
    const blogBodyContainerRef = useRef();
    const [isLiked, setIsLiked] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isThemeLight, setIsThemeLight] = useState(true);
    const [selectedText, setSelectedText] = useState("");
    const [showSharePopup, setShowSharePopup] = useState(false);
    const [showHighlightTooltip, setShowHighlightTooltip] = useState(false);
    const [highlightPosition, setHighlightPosition] = useState({ x: 0, y: 0 });
    const [tableOfContents, setTableOfContents] = useState([]);
    
    // Toggle theme
    const toggleTheme = () => {
        setIsThemeLight(!isThemeLight);
    };
    
    // Handle text selection for highlight feature
    const handleTextSelection = () => {
        const selection = window.getSelection();
        if (selection.toString().length > 0) {
            setSelectedText(selection.toString());
            
            // Get position for the highlight tooltip
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            
            setHighlightPosition({
                x: rect.left + (rect.width / 2),
                y: rect.top - 10 + window.scrollY
            });
            
            setShowHighlightTooltip(true);
        } else {
            setShowHighlightTooltip(false);
        }
    };
    
    // Share highlighted text
    const shareHighlightedText = (platform) => {
        let shareUrl = '';
        const postUrl = window.location.href;
        
        switch(platform) {
            case 'twitter':
                shareUrl = `https://twitter.com/intent/tweet?text="${selectedText}" - From: ${postUrl}`;
                break;
            case 'linkedin':
                shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${postUrl}&summary=${selectedText}`;
                break;
            case 'facebook':
                shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${postUrl}&quote=${selectedText}`;
                break;
            default:
                // Copy to clipboard
                navigator.clipboard.writeText(`"${selectedText}" - From: ${postUrl}`);
                alert("Quote copied to clipboard!");
                setShowHighlightTooltip(false);
                return;
        }
        
        window.open(shareUrl, '_blank');
        setShowHighlightTooltip(false);
    };
    
    // Extract headings for table of contents
    const extractTableOfContents = () => {
        if (blogBodyContainerRef.current) {
            const headings = blogBodyContainerRef.current.querySelectorAll('h1, h2, h3');
            const toc = Array.from(headings).map((heading, index) => {
                // Add IDs to headings for anchor links
                if (!heading.id) {
                    heading.id = `heading-${index}`;
                }
                
                return {
                    id: heading.id,
                    text: heading.textContent,
                    level: parseInt(heading.tagName.substring(1))
                };
            });
            
            setTableOfContents(toc);
        }
    };
    
    useEffect(() => {
        const blogData = stringToHTML(content);
        blogBodyContainerRef.current.innerHTML = blogData;
        document.title = `${title} | Resources | QUBITWARE`;
        window.scrollTo(0, 0);
        
        // Extract table of contents after content is loaded
        setTimeout(extractTableOfContents, 300);
        
        // Add event listener for text selection
        document.addEventListener('mouseup', handleTextSelection);
        
        return () => {
            document.removeEventListener('mouseup', handleTextSelection);
        };
    }, [content, title]);
    
    // Animation variants
    const fadeIn = {
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0, transition: { duration: 0.5 } }
    };

    return (
        <div className={`w-full ${isThemeLight ? 'bg-white text-gray-900' : 'bg-gray-900 text-gray-100'} transition-colors duration-300`}>
            {/* Theme toggle and back button */}
            <div className="fixed top-24 right-6 z-50 flex flex-col gap-4">
                <button 
                    onClick={toggleTheme}
                    className={`p-3 rounded-full shadow-lg ${isThemeLight ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'} transition-all duration-300`}
                >
                    {isThemeLight ? <FaMoon /> : <FaSun />}
                </button>
            </div>
            
            {/* Progress indicator */}
            <div className="fixed top-16 left-0 w-full h-1 bg-gray-200 z-40">
                <motion.div 
                    className="h-full bg-gradient-to-r from-blue-500 to-indigo-600"
                    initial={{ width: "0%" }}
                    // This will animate based on scroll position
                    style={{ 
                        width: typeof window !== 'undefined' 
                            ? `${(window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100}%` 
                            : "0%" 
                    }}
                />
            </div>
            
            {/* Back button */}
            <div className="container mx-auto px-4 py-6">
                <button 
                    onClick={() => navigate('/blogs')}
                    className={`flex items-center gap-2 ${isThemeLight ? 'text-gray-600 hover:text-blue-600' : 'text-gray-300 hover:text-blue-400'} transition-colors`}
                >
                    <FaArrowLeft />
                    <span>Back to Resources</span>
                </button>
            </div>
            
            <section className={`pb-20 ${isThemeLight ? 'bg-white' : 'bg-gray-900'} transition-colors duration-300`}>
                <div className="container mx-auto px-4 flex flex-col text-left md:px-6 lg:px-8">
                    <div className="flex flex-col lg:flex-row lg:gap-12">
                        {/* Main content column */}
                        <div className="lg:w-3/4">
                            {/* Blog Header */}
                            <motion.div 
                                variants={fadeIn}
                                initial="initial"
                                animate="animate"
                                className="mb-10"
                            >
                                {/* Tags */}
                                <div className="flex gap-2 my-4 flex-wrap">
                                    {tags &&
                                        tags.map((tag, index) => (
                                            <Tag 
                                                key={index} 
                                                name={tag} 
                                                type={isThemeLight ? "default" : "filled"}
                                            />
                                        ))}
                                </div>
                                
                                {/* Title */}
                                <h1 className={`text-4xl md:text-5xl lg:text-6xl font-extrabold leading-tight mb-6 ${isThemeLight ? '' : 'text-white'}`}>
                                    {title}
                                </h1>
                                
                                {/* Description */}
                                <p className={`text-xl md:text-2xl font-medium mb-6 ${isThemeLight ? 'text-gray-600' : 'text-gray-300'}`}>
                                    {short_description}
                                </p>
                                
                                {/* Author and meta info */}
                                <div className="flex flex-wrap items-center gap-6 mb-8">
                                    <div className="flex items-center gap-3">
                                        <div className="w-12 h-12 bg-gradient-to-br from-blue-500 to-indigo-600 rounded-full flex items-center justify-center text-white font-bold text-lg">
                                            {author.charAt(0)}
                                        </div>
                                        <div>
                                            <p className="font-bold">{author}</p>
                                            <div className="flex items-center gap-2 text-sm">
                                                <Timestamp relative date={created_at} />
                                                <span className="w-1 h-1 rounded-full bg-gray-400"></span>
                                                <span>{read_time} min read</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* Share and save buttons */}
                                    <div className="flex gap-4 ml-auto">
                                        <div className="relative">
                                            <button 
                                                onClick={() => setShowSharePopup(!showSharePopup)}
                                                className={`p-2 rounded-full ${isThemeLight ? 'hover:bg-gray-100' : 'hover:bg-gray-800'} transition-colors`}
                                            >
                                                <FaShare />
                                            </button>
                                            
                                            {/* Share popup */}
                                            {showSharePopup && (
                                                <div className={`absolute right-0 mt-2 p-3 rounded-lg shadow-lg z-10 ${isThemeLight ? 'bg-white' : 'bg-gray-800'}`}>
                                                    <div className="flex gap-3">
                                                        <button 
                                                            onClick={() => window.open(`https://twitter.com/intent/tweet?url=${window.location.href}&text=${title}`, '_blank')}
                                                            className="p-2 rounded-full bg-[#1DA1F2] text-white hover:opacity-80 transition-opacity"
                                                        >
                                                            <FaTwitter />
                                                        </button>
                                                        <button 
                                                            onClick={() => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`, '_blank')}
                                                            className="p-2 rounded-full bg-[#0077B5] text-white hover:opacity-80 transition-opacity"
                                                        >
                                                            <FaLinkedin />
                                                        </button>
                                                        <button 
                                                            onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`, '_blank')}
                                                            className="p-2 rounded-full bg-[#4267B2] text-white hover:opacity-80 transition-opacity"
                                                        >
                                                            <FaFacebook />
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        
                                        <button 
                                            onClick={() => setIsLiked(!isLiked)}
                                            className={`p-2 rounded-full ${isThemeLight ? 'hover:bg-gray-100' : 'hover:bg-gray-800'} transition-colors ${isLiked ? 'text-blue-600' : ''}`}
                                        >
                                            {isLiked ? <FaThumbsUp /> : <FaRegThumbsUp />}
                                        </button>
                                        
                                        <button 
                                            onClick={() => setIsSaved(!isSaved)}
                                            className={`p-2 rounded-full ${isThemeLight ? 'hover:bg-gray-100' : 'hover:bg-gray-800'} transition-colors ${isSaved ? 'text-blue-600' : ''}`}
                                        >
                                            {isSaved ? <FaBookmark /> : <FaRegBookmark />}
                                        </button>
                                    </div>
                                </div>
                            </motion.div>
                            
                            {/* Banner Image */}
                            <motion.div 
                                variants={fadeIn}
                                initial="initial"
                                animate="animate"
                                className="mb-10"
                            >
                                <img
                                    src={banner}
                                    alt={title}
                                    className="w-full aspect-video object-cover object-center rounded-xl shadow-md"
                                />
                            </motion.div>
                            
                            {/* Article Content */}
                            <motion.article
                                variants={fadeIn}
                                initial="initial"
                                animate="animate"
                                className={`prose max-w-none ${isThemeLight ? 'prose-slate' : 'prose-invert'} 
                                    prose-img:rounded-xl prose-img:shadow-md prose-headings:font-bold prose-a:text-blue-600
                                    md:prose-lg lg:prose-xl prose-pre:bg-gray-800 prose-pre:text-gray-100
                                    prose-blockquote:border-l-blue-500 prose-blockquote:bg-blue-50 prose-blockquote:py-1 prose-blockquote:px-4 
                                    prose-blockquote:rounded-r-lg ${!isThemeLight && 'prose-blockquote:bg-gray-800'}
                                `}
                                ref={blogBodyContainerRef}
                            ></motion.article>
                            
                            {/* Author bio */}
                            <div className={`mt-16 p-6 rounded-xl ${isThemeLight ? 'bg-gray-50' : 'bg-gray-800'} flex flex-col md:flex-row gap-6 items-center md:items-start`}>
                                <div className="w-20 h-20 bg-gradient-to-br from-blue-500 to-indigo-600 rounded-full flex items-center justify-center text-white font-bold text-2xl">
                                    {author.charAt(0)}
                                </div>
                                <div className="flex-1 text-center md:text-left">
                                    <h3 className="text-xl font-bold mb-2">Written by {author}</h3>
                                    <p className={`mb-4 ${isThemeLight ? 'text-gray-600' : 'text-gray-300'}`}>
                                        Technical expert at QUBITWARE with a passion for AI and innovative technology solutions.
                                    </p>
                                    <div className="flex gap-3 justify-center md:justify-start">
                                        <a href="#" className={`p-2 rounded-full ${isThemeLight ? 'bg-gray-200 hover:bg-gray-300' : 'bg-gray-700 hover:bg-gray-600'} transition-colors`}>
                                            <FaTwitter />
                                        </a>
                                        <a href="#" className={`p-2 rounded-full ${isThemeLight ? 'bg-gray-200 hover:bg-gray-300' : 'bg-gray-700 hover:bg-gray-600'} transition-colors`}>
                                            <FaLinkedin />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Sidebar - Table of Contents */}
                        <div className="hidden lg:block lg:w-1/4 relative">
                            <div className={`sticky top-24 p-6 rounded-xl ${isThemeLight ? 'bg-gray-50' : 'bg-gray-800'}`}>
                                <h3 className="text-lg font-bold mb-4">Table of Contents</h3>
                                {tableOfContents.length > 0 ? (
                                    <ul className="space-y-2">
                                        {tableOfContents.map((item, index) => (
                                            <li 
                                                key={index} 
                                                className={`${item.level === 2 ? 'ml-0' : 'ml-4'} ${isThemeLight ? 'text-gray-700' : 'text-gray-300'}`}
                                            >
                                                <a 
                                                    href={`#${item.id}`} 
                                                    className={`block p-2 rounded hover:bg-gray-200 ${!isThemeLight && 'hover:bg-gray-700'} transition-colors`}
                                                >
                                                    {item.text}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className={`${isThemeLight ? 'text-gray-500' : 'text-gray-400'}`}>
                                        No headings found in this article.
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    
                    {/* Related Articles */}
                    {relatedBlogs && relatedBlogs.length > 0 && (
                        <div className="mt-20">
                            <h2 className="text-3xl font-bold mb-8">
                                Related <GradientText>Resources</GradientText>
                            </h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                {relatedBlogs.map((blog, index) => (
                                    <BlogCard key={index} details={blog} />
                                ))}
                            </div>
                        </div>
                    )}
                    
                    {/* Email subscription CTA */}
                    <div className={`mt-20 p-8 rounded-xl ${isThemeLight ? 'bg-blue-50' : 'bg-blue-900'} text-center`}>
                        <h2 className="text-2xl font-bold mb-4">Stay Updated</h2>
                        <p className={`mb-6 ${isThemeLight ? 'text-gray-700' : 'text-gray-200'}`}>
                            Subscribe to our newsletter to get the latest updates on AI, IT, and technology trends.
                        </p>
                        <div className="flex flex-col sm:flex-row gap-3 max-w-md mx-auto">
                            <input 
                                type="email" 
                                placeholder="Your email address" 
                                className={`flex-1 p-3 rounded-lg ${isThemeLight ? 'bg-white' : 'bg-gray-800 text-white'} border-2 ${isThemeLight ? 'border-gray-200' : 'border-gray-700'} focus:outline-none focus:border-blue-500`}
                            />
                            <button className="bg-gradient-to-r from-blue-600 to-indigo-600 text-white font-medium py-3 px-6 rounded-lg hover:opacity-90 transition-opacity">
                                Subscribe
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            
            {/* Highlight Tooltip */}
            {showHighlightTooltip && (
                <div 
                    className="fixed z-50 bg-white rounded-lg shadow-xl p-2 transform -translate-x-1/2"
                    style={{ 
                        top: `${highlightPosition.y}px`, 
                        left: `${highlightPosition.x}px` 
                    }}
                >
                    <div className="flex gap-2">
                        <button 
                            onClick={() => shareHighlightedText('twitter')}
                            className="p-2 rounded-full bg-[#1DA1F2] text-white hover:opacity-80 transition-opacity"
                        >
                            <FaTwitter size={14} />
                        </button>
                        <button 
                            onClick={() => shareHighlightedText('linkedin')}
                            className="p-2 rounded-full bg-[#0077B5] text-white hover:opacity-80 transition-opacity"
                        >
                            <FaLinkedin size={14} />
                        </button>
                        <button 
                            onClick={() => shareHighlightedText('copy')}
                            className="p-2 rounded-full bg-gray-800 text-white hover:opacity-80 transition-opacity"
                        >
                            <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 5H6C4.89543 5 4 5.89543 4 7V19C4 20.1046 4.89543 21 6 21H16C17.1046 21 18 20.1046 18 19V17M8 5C8 6.10457 8.89543 7 10 7H12C13.1046 7 14 6.10457 14 5M8 5C8 3.89543 8.89543 3 10 3H12C13.1046 3 14 3.89543 14 5M14 5H16C17.1046 5 18 5.89543 18 7V10M20 14V16M20 16V18M20 16H18M20 16H22" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                    </div>
                </div>
            )}
            
            <Footer varient={isThemeLight ? "blue" : "dark"} />
        </div>
    );
};

export default BlogPost;
