import React from 'react';

const TagsFilter = ({ tags, selectedTag, onTagSelect, isThemeLight }) => {
    if (!tags || !Array.isArray(tags)) {
        // Handle the case when tags are not available or not an array
        return (
            <div className="p-4 text-gray-500">
                No tags available
            </div>
        );
    }

    return (
        <div className="space-y-2">
            {tags.map((tag, index) => {
                return(
                <div 
                    key={index}
                    onClick={() => onTagSelect(tag, selectedTag === tag)}
                    className={`px-3 py-2 rounded-md cursor-pointer transition-colors ${
                        selectedTag === tag 
                            ? `${isThemeLight ? 'bg-blue-100 text-blue-700' : 'bg-blue-900 text-blue-100'}` 
                            : `${isThemeLight ? 'hover:bg-gray-100' : 'hover:bg-gray-700'}`
                    }`}
                >
                    <div className="flex items-center justify-between">
                        <span>{tag?.name}</span>
                        {selectedTag === tag && (
                            <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                            </svg>
                        )}
                    </div>
                </div>
            )})}
        </div>
    );
};

export default TagsFilter;
