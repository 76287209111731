import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { motion } from "framer-motion"; // Assuming framer-motion is installed
import axios from "axios";
import ServiceCard from "../components/ServiceCard.component.";
import GradientText from "../components/GradientText.component";
import Footer from "../components/Footer.component";
import GradientContactCard from "../components/GradientContactCard.component";
import AnimationWrapper from "../components/AnimationWrapper.component";
import BlogCardBig from "../components/BlogCardBig.component";
import { allServicesList } from "./Services.page";
import { FaRocket, FaLightbulb, FaCode, FaShieldAlt } from "react-icons/fa";

export const fetchXLatestBlogs = async (blog_count = 4) => {
    return await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/blog/4/`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export default function Home() {
    const navigate = useNavigate();
    const services = allServicesList.slice(0, 4);
    const blogsData = useLoaderData();
    const [animateStats, setAnimateStats] = useState(false);

    useEffect(() => {
        document.title = "Home | QUBITWARE";
    }, []);

    // Animation variants
    const fadeIn = {
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0, transition: { duration: 0.6 } }
    };
    
    const staggerContainer = {
        animate: {
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    // Technologies we work with
    const technologies = [
        { name: "TensorFlow", logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2d/Tensorflow_logo.svg/1200px-Tensorflow_logo.svg.png" },
        { name: "PyTorch", logo: "https://pytorch.org/assets/images/pytorch-logo.png" },
        { name: "React", logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png" },
        { name: "AWS", logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/1200px-Amazon_Web_Services_Logo.svg.png" },
        { name: "Azure", logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Microsoft_Azure.svg/1200px-Microsoft_Azure.svg.png" },
        { name: "Node.js", logo: "https://nodejs.org/static/images/logo.svg" },
        { name: "Docker", logo: "https://www.docker.com/wp-content/uploads/2022/03/vertical-logo-monochromatic.png" },
        { name: "Kubernetes", logo: "https://kubernetes.io/images/logo-header.png" }
    ];

    // Company stats
    const stats = [
        { value: "5+", label: "Projects Delivered" },
        { value: "98%", label: "Client Satisfaction" },
        { value: "10+", label: "Industries Served" },
        { value: "24/7", label: "Expert Support" }
    ];

    return (
        <AnimationWrapper className="flex flex-col">
            {/* Hero section - Enhanced with modern design */}
            <section className="relative overflow-hidden bg-gradient-to-br from-white to-blue-50">
                {/* Background decorative elements */}
                <div className="absolute top-0 right-0 w-1/3 h-1/3 bg-gradient-to-br from-blue-400/5 to-indigo-500/10 rounded-bl-full"></div>
                <div className="absolute bottom-0 left-0 w-1/4 h-1/4 bg-gradient-to-tr from-blue-400/5 to-indigo-500/10 rounded-tr-full"></div>
                
                <div className="container mx-auto py-24 md:py-32 lg:py-40 px-4 flex flex-col items-center gap-y-8 relative z-10">
                    <motion.div 
                        initial="initial" 
                        animate="animate" 
                        variants={staggerContainer}
                        className="max-w-5xl mx-auto text-center"
                    >
                        <motion.div variants={fadeIn} className="inline-block mb-6">
                            <span className="bg-blue-100 text-blue-700 py-1 px-4 rounded-full text-sm font-medium">
                                AI & IT Solutions Provider
                            </span>
                        </motion.div>
                        
                        <motion.h1 variants={fadeIn} className="text-5xl md:text-6xl lg:text-7xl font-extrabold mb-8">
                            <GradientText className="leading-tight">
                                Empowering IT <br /> Empowering You
                            </GradientText>
                        </motion.h1>
                        
                        <motion.p 
                            variants={fadeIn}
                            className="text-xl md:text-2xl text-gray-600 mb-10 max-w-3xl mx-auto"
                        >
                            Empower your business with{" "}
                            <GradientText className="font-bold">
                                QUBITWARE
                            </GradientText>
                            . Our expert-led IT managed services, cloud services,
                            advanced cybersecurity measures, full-stack agency
                            tools, and more are designed to support your
                            organization's goals.
                        </motion.p>
                        
                        <motion.div 
                            variants={fadeIn}
                            className="flex flex-col sm:flex-row gap-4 justify-center"
                        >
                            <Link
                                to="/services"
                                className="bg-gradient-to-r from-blue-600 to-indigo-600 text-white hover:from-blue-700 hover:to-indigo-700 px-8 py-4 rounded-lg font-bold text-lg transition-all shadow-md hover:shadow-lg"
                            >
                                Explore Services
                            </Link>
                            <Link
                                to="/contact-us"
                                className="bg-white text-blue-700 hover:bg-blue-50 border border-blue-200 px-8 py-4 rounded-lg font-bold text-lg transition-all shadow-sm hover:shadow-md"
                            >
                                Get in Touch
                            </Link>
                        </motion.div>
                    </motion.div>
                </div>
                
                {/* Wave divider */}
                {/* <div className="w-full overflow-hidden">
                    <svg preserveAspectRatio="none" viewBox="0 0 1200 120" xmlns="http://www.w3.org/2000/svg" 
                         className="w-full h-20 text-cblue">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" 
                              fill="currentColor"></path>
                    </svg>
                </div> */}
            </section>

            {/* Services section - Enhanced with better organization */}
            <section className="bg-cblue py-20 text-left">
                <div className="container mx-auto flex flex-col gap-12 px-4">
                    <div className="text-center">
                        <h2 className="text-4xl text-white font-extrabold mb-4 lg:text-5xl">
                            Our Offerings
                        </h2>
                        <p className="text-xl text-gray-200 font-light max-w-3xl mx-auto">
                            <span className="bg-cgreen px-1 rounded-lg">
                                Reliable
                            </span>
                            {", "}
                            <span className="bg-cgreen px-1 rounded-lg">
                                customized
                            </span>{" "}
                            IT Services designed to support your business and give
                            you peace of mind.
                        </p>
                    </div>
                    
                    {/* Featured services with enhanced cards */}
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
                        {services.map((service, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0, y: 30 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                                viewport={{ once: true }}
                            >
                                <ServiceCard
                                    key={index}
                                    {...service}
                                    img_url={`/service_imgs/${service.id}.png`}
                                />
                            </motion.div>
                        ))}
                    </div>
                    
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true }}
                        className="text-center"
                    >
                        <Link
                            className="inline-block text-white text-lg outline outline-2 hover:bg-white hover:text-cblue rounded-3xl px-8 py-3 font-bold cursor-pointer shadow-lg shadow-black/20 hover:shadow-none transition-all"
                            to="/services/"
                        >
                            View All Services
                        </Link>
                    </motion.div>
                </div>
            </section>

            {/* New section: Why Choose Us */}
            <section className="py-20 bg-white">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-16">
                        <GradientText className="text-4xl font-extrabold mb-4">
                            Why Choose QUBITWARE
                        </GradientText>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            Partner with us for innovative solutions tailored to your unique business needs
                        </p>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.1 }}
                            viewport={{ once: true }}
                            className="bg-blue-50 rounded-xl p-8 text-center flex flex-col items-center"
                        >
                            <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-6">
                                <FaRocket className="text-blue-600 text-2xl" />
                            </div>
                            <h3 className="text-xl font-bold text-gray-800 mb-4">Innovation-Driven</h3>
                            <p className="text-gray-600">
                                We stay ahead of technology trends to deliver cutting-edge solutions
                            </p>
                        </motion.div>
                        
                        <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            viewport={{ once: true }}
                            className="bg-indigo-50 rounded-xl p-8 text-center flex flex-col items-center"
                        >
                            <div className="w-16 h-16 bg-indigo-100 rounded-full flex items-center justify-center mb-6">
                                <FaLightbulb className="text-indigo-600 text-2xl" />
                            </div>
                            <h3 className="text-xl font-bold text-gray-800 mb-4">Industry Expertise</h3>
                            <p className="text-gray-600">
                                Deep domain knowledge across multiple sectors and technologies
                            </p>
                        </motion.div>
                        
                        <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            viewport={{ once: true }}
                            className="bg-purple-50 rounded-xl p-8 text-center flex flex-col items-center"
                        >
                            <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mb-6">
                                <FaCode className="text-purple-600 text-2xl" />
                            </div>
                            <h3 className="text-xl font-bold text-gray-800 mb-4">Custom Solutions</h3>
                            <p className="text-gray-600">
                                Tailored approach to meet your specific business requirements
                            </p>
                        </motion.div>
                        
                        <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            viewport={{ once: true }}
                            className="bg-teal-50 rounded-xl p-8 text-center flex flex-col items-center"
                        >
                            <div className="w-16 h-16 bg-teal-100 rounded-full flex items-center justify-center mb-6">
                                <FaShieldAlt className="text-teal-600 text-2xl" />
                            </div>
                            <h3 className="text-xl font-bold text-gray-800 mb-4">Reliable Support</h3>
                            <p className="text-gray-600">
                                Dedicated team committed to your success with 24/7 availability
                            </p>
                        </motion.div>
                    </div>
                </div>
            </section>

            {/* New section: Stats Counter */}
            <section className="py-16 bg-gray-50">
                <div className="container mx-auto px-4">
                    <motion.div 
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        onViewportEnter={() => setAnimateStats(true)}
                        viewport={{ once: true }}
                        className="grid grid-cols-2 md:grid-cols-4 gap-6"
                    >
                        {stats.map((stat, index) => (
                            <div key={index} className="bg-white p-6 rounded-xl shadow-sm text-center">
                                <h3 className="text-4xl lg:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600 mb-2">
                                    {stat.value}
                                </h3>
                                <p className="text-gray-600">{stat.label}</p>
                            </div>
                        ))}
                    </motion.div>
                </div>
            </section>

            {/* Technologies we work with - New section */}
            <section className="py-20 bg-white">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-16">
                        <GradientText className="text-4xl font-extrabold mb-4">
                            Technologies We Work With
                        </GradientText>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            We leverage cutting-edge technologies to deliver exceptional solutions
                        </p>
                    </div>
                    
                    <div className="flex flex-wrap justify-center gap-8">
                        {technologies.map((tech, index) => (
                            <motion.div 
                                key={index}
                                initial={{ opacity: 0, scale: 0.8 }}
                                whileInView={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.4, delay: index * 0.05 }}
                                viewport={{ once: true }}
                                className="w-24 h-24 flex flex-col items-center justify-center"
                            >
                                <img 
                                    src={tech.logo} 
                                    alt={tech.name} 
                                    className="h-12 object-contain mb-2"
                                    // onError={(e) => {
                                    //     e.target.src = "/logo.png"; // Fallback image
                                    // }}
                                />
                                <p className="text-sm text-gray-600 text-center">{tech.name}</p>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Blogs section - Enhanced with better presentation */}
            {blogsData && blogsData.length < 3 ? (
                <></>
            ) : (
                <section className="py-20 bg-gray-50">
                    <div className="container mx-auto flex flex-col text-left gap-8 px-4">
                        <div className="text-center mb-8">
                            <GradientText
                                children={"Latest Stories"}
                                className="text-4xl font-extrabold mb-4"
                            />
                            <p
                                className="text-xl text-gray-600 max-w-3xl mx-auto"
                            >
                                Explore our latest blogs, articles, and stories to stay
                                updated with the latest trends in the tech industry.
                            </p>
                        </div>

                        {/* Blogs with enhanced animations */}
                        <div
                            className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-3"
                        >
                            {blogsData.slice(0, 3).map((blog, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 30 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: index * 0.1 }}
                                    viewport={{ once: true }}
                                >
                                    <BlogCardBig blogData={blog} />
                                </motion.div>
                            ))}
                        </div>

                        {/* More Blogs Button */}
                        <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                            viewport={{ once: true }}
                            className="text-center"
                        >
                            <button
                                onClick={() => navigate(`/blogs`)}
                                className="py-3 px-8 text-lg text-slate-600 hover:text-white font-bold bg-white hover:bg-slate-600 outline outline-1 outline-slate-200 hover:outline-none rounded-full cursor-pointer shadow-sm hover:shadow-md transition-all duration-300"
                            >
                                View All Blog Posts
                            </button>
                        </motion.div>
                    </div>
                </section>
            )}

            {/* Testimonial Section - New section */}
            {/* <section className="py-20 bg-white">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-16">
                        <GradientText className="text-4xl font-extrabold mb-4">
                            Client Testimonials
                        </GradientText>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            See what our clients have to say about working with us
                        </p>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.1 }}
                            viewport={{ once: true }}
                            className="bg-gray-50 p-8 rounded-xl relative"
                        >
                            <div className="absolute -top-5 left-8 text-5xl text-blue-400">"</div>
                            <p className="text-gray-600 mb-6 pt-4">
                                QUBITWARE's AI solutions have transformed our data processing capabilities. 
                                Their team delivered beyond our expectations and continues to provide excellent support.
                            </p>
                            <div className="flex items-center">
                                <div className="w-12 h-12 bg-blue-200 rounded-full mr-4"></div>
                                <div>
                                    <h4 className="font-bold">Sarah Johnson</h4>
                                    <p className="text-gray-500 text-sm">CTO, TechInnovate</p>
                                </div>
                            </div>
                        </motion.div>
                        
                        <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            viewport={{ once: true }}
                            className="bg-gray-50 p-8 rounded-xl relative"
                        >
                            <div className="absolute -top-5 left-8 text-5xl text-blue-400">"</div>
                            <p className="text-gray-600 mb-6 pt-4">
                                The cloud migration project was completed ahead of schedule and under budget.
                                QUBITWARE's expertise in cloud services is unmatched in the industry.
                            </p>
                            <div className="flex items-center">
                                <div className="w-12 h-12 bg-indigo-200 rounded-full mr-4"></div>
                                <div>
                                    <h4 className="font-bold">Michael Chen</h4>
                                    <p className="text-gray-500 text-sm">Director, GlobalNet</p>
                                </div>
                            </div>
                        </motion.div>
                        
                        <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            viewport={{ once: true }}
                            className="bg-gray-50 p-8 rounded-xl relative"
                        >
                            <div className="absolute -top-5 left-8 text-5xl text-blue-400">"</div>
                            <p className="text-gray-600 mb-6 pt-4">
                                We partnered with QUBITWARE for our security compliance needs and they exceeded
                                our expectations. Their thorough approach gives us peace of mind.
                            </p>
                            <div className="flex items-center">
                                <div className="w-12 h-12 bg-purple-200 rounded-full mr-4"></div>
                                <div>
                                    <h4 className="font-bold">Jessica Williams</h4>
                                    <p className="text-gray-500 text-sm">COO, Nexus Healthcare</p>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section> */}

            {/* Contact us section - Enhanced with better presentation */}
            <section className="py-20 bg-gray-50">
                <motion.div 
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    viewport={{ once: true }}
                    className="container mx-auto px-4"
                >
                    <GradientContactCard
                        message={
                            "Ready to streamline your IT solutions? Get in touch with us today!"
                        }
                    />
                </motion.div>
            </section>
            
            <Footer varient="blue" />
        </AnimationWrapper>
    );
}
