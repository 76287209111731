import axios from "axios";
import Cookies from "js-cookie";

/**
 * Sends a POST request with CSRF token attached.
 * @param {string} url - The API endpoint
 * @param {object} payload - Data to send in the request body
 * @returns {Promise} Axios response
 */
export const sendPostRequest = async (url, payload) => {
    const csrfToken = Cookies.get("csrftoken");

    try {
        const headers = {
            "X-CSRFToken": csrfToken,
            ...(payload instanceof FormData ? {} : { "Content-Type": "application/json" }),
        };

        const response = await axios.post(url, payload, {
            withCredentials: true,
            headers,
        });
        return response;
    } catch (error) {
        throw error.response;
    }
};
