import React, { useState, useRef, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useLoaderData } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import MobileInput from "./MobileInput.component";
import { FaRegCopy, FaCheck } from "react-icons/fa";
import { FaReddit, FaXTwitter, FaLinkedin, FaRegFilePdf } from "react-icons/fa6";
import { stringToHTML } from "../pages/BlogPost.page";
import { useReCaptcha } from '../hooks/useReCaptcha';
import { sendPostRequest } from '../utils/axiosUtils';
import { motion, AnimatePresence } from "framer-motion";
import GradientText from "./GradientText.component";
import GradientBall from "./GradientBall.component";

// TODO: add slide-in-out animation for the application form
export default function JobApplication() {
    const [showApplicationForm, setShowApplicationForm] = useState(false);
    const { title, job_location, short_description, content, department_name, employment_type, min_experience, job_id } = useLoaderData();
    const navigate = useNavigate();

    const toggleShowApplicationForm = () =>
        setShowApplicationForm((prev) => !prev);

    useEffect(() => {
        // Scroll to top when component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col text-left bg-gradient-to-b from-blue-50/50 to-white"
        >
            <section className="py-8 md:py-12">
                <div className="container max-w-screen-xl mx-auto px-4">
                    <motion.div 
                        initial={{ y: 20 }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="grid grid-cols-1 lg:grid-cols-4 gap-8"
                    >
                        <div className="lg:col-span-3 bg-white shadow-lg rounded-2xl overflow-hidden">
                            {/* Navigation */}
                            <div className="bg-gradient-to-r from-blue-50 to-indigo-50 py-4 px-8">
                                <button
                                    className="flex items-center gap-2 text-gray-600 hover:text-cblue transition-colors"
                                    onClick={() => navigate("/careers")}
                                >
                                    <IoIosArrowBack />
                                    <span className="font-medium">Back to Job Openings</span>
                                </button>
                            </div>
                            
                            {/* Job Title */}
                            <div className="p-8 border-b border-gray-100">
                                <JobHeader
                                    title={title}
                                    location={job_location}
                                    department={department_name}
                                />
                            </div>
                            
                            {/* Content */}
                            <AnimatePresence mode="wait">
                                {showApplicationForm ? (
                                    <motion.div
                                        key="form"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -20 }}
                                        transition={{ duration: 0.4 }}
                                    >
                                        <JobApplicationForm
                                            onCancel={toggleShowApplicationForm}
                                            job_id={job_id}
                                            jobTitle={title}
                                        />
                                    </motion.div>
                                ) : (
                                    <motion.div
                                        key="description"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -20 }}
                                        transition={{ duration: 0.4 }}
                                    >
                                        <JobDescription 
                                            short_description={short_description} 
                                            content={content}
                                        />
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                        
                        {/* Sidebar */}
                        <motion.div
                            initial={{ opacity: 0, x: 20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            <Sidebar
                                isFormVisible={showApplicationForm}
                                toggleForm={toggleShowApplicationForm}
                                job_location={job_location}
                                department_name={department_name}
                                employment_type={employment_type}
                                min_experience={min_experience}
                            />
                        </motion.div>
                    </motion.div>
                </div>
            </section>
        </motion.div>
    );
}

const Sidebar = ({ isFormVisible, toggleForm, job_location, department_name, employment_type, min_experience}) => {
    const jobDetails = [
        { label: "Location", value: job_location },
        { label: "Department", value: department_name },
        { label: "Employment Type", value: { FULL_TIME: 'Full-time', PART_TIME: 'Part-Time', NEED_BASIS: 'Need-Basis' }[employment_type] },
        { label: "Minimum Experience", value: min_experience > 0 ? `${min_experience}+ years` : 'No prior experience required' },
    ];

    return (
        <div className="bg-white shadow-lg rounded-2xl p-6 sticky top-24 flex flex-col gap-6">
            <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className={`w-full py-3 px-4 rounded-full text-white font-semibold transition-all duration-300 ${
                    isFormVisible 
                    ? "bg-gradient-to-r from-indigo-600 to-blue-600 hover:from-indigo-700 hover:to-blue-700" 
                    : "bg-gradient-to-r from-cblue to-cgreen hover:from-blue-600 hover:to-green-600"
                } shadow-md hover:shadow-lg`}
                onClick={toggleForm}
            >
                {isFormVisible ? "← View Job Description" : "Apply for This Position"}
            </motion.button>
            
            <div className="bg-gray-50 rounded-xl p-4">
                <p className="text-sm text-gray-600 mb-2">Share this job:</p>
                <CopyToClipboardLink link={window.location.href} />
                <div className="mt-3">
                    <SocialShare jobUrl={window.location.href} jobTitle={"Software engineer"} />
                </div>
            </div>
            
            <div className="divide-y divide-gray-100">
                {jobDetails.map(({ label, value }) => (
                    <div key={label} className="py-3 first:pt-0 last:pb-0">
                        <h3 className="text-sm text-gray-500 mb-1">{label}</h3>
                        <p className="font-medium text-gray-800">{value}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

const JobHeader = ({ title, location, department }) => (
    <div>
        <div className="inline-block px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm font-medium mb-3">
            {department}
        </div>
        <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-3">{title}</h1>
        <div className="flex items-center text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
            </svg>
            <span>{location}</span>
        </div>
    </div>
);

const JobDescription = ({ short_description, content }) => {
    const jobDescriptionContainerRef = useRef();
    
    useEffect(() => {
        const jobData = stringToHTML(content);
        jobDescriptionContainerRef.current.innerHTML = jobData;
    }, [content]);
    
    return (
        <div className="p-8">
            <div className="mb-8 pb-8 border-b border-gray-100">
                <h2 className="text-xl font-bold text-gray-800 mb-4">
                    Overview
                </h2>
                <p className="text-gray-700 leading-relaxed text-lg">
                    {short_description}
                </p>
            </div>
            
            <div>
                <h2 className="text-xl font-bold text-gray-800 mb-4">
                    Job Description
                </h2>
                <article
                    className="prose prose-lg max-w-full prose-headings:text-gray-800 prose-headings:font-bold prose-p:text-gray-600 prose-p:leading-relaxed prose-strong:text-gray-700 prose-li:text-gray-600 prose-li:leading-relaxed prose-img:rounded-lg prose-a:text-blue-600"
                    ref={jobDescriptionContainerRef}
                ></article>
            </div>
        </div>
    );
};

const JobApplicationForm = ({ onCancel, job_id, jobTitle }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [applicationResponse, setApplicationResponse] = useState(null);
    const { executeReCaptcha } = useReCaptcha(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const formSubmitHandler = async (data) => {
        setIsSubmitting(true);
        try {
            const reCaptcha_token = await executeReCaptcha("SUBMIT_JOB_APPLICATION_FORM");

            const formData = new FormData();
            formData.append("job", job_id);
            formData.append('first_name', data['firstName']);
            formData.append('last_name', data['lastName']);
            formData.append('email', data['email']);
            formData.append('phone', data['phone']);
            formData.append('address', data['address']);
            formData.append("resume", data['resume'][0]);
            formData.append('date_available', data['dateAvailable']);
            formData.append('website', data['portfolioLink']);
            formData.append('linkedin', data['linkedinUrl']);
            formData.append('college_university', data['collegeName']);
            formData.append("reCaptcha_token", reCaptcha_token);

            const response = await sendPostRequest(`${process.env.REACT_APP_BACKEND_URL}/career/job-application/submit/`, formData);
            setApplicationResponse(response);
        } catch (error) {
            console.error("Error submitting application:", error);
            alert("Could not submit application!\n Please re-try after some time...");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="p-8">
            <AnimatePresence mode="wait">
                {(!applicationResponse || applicationResponse.status !== 201) ? (
                    <motion.div
                        key="application-form"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <div className="mb-6 pb-4 border-b border-gray-100">
                            <h2 className="text-2xl font-bold text-gray-800 mb-2">
                                Apply for <GradientText>{jobTitle}</GradientText>
                            </h2>
                            <p className="text-gray-600">
                                Please fill out the form below to apply for this position. Fields marked with an asterisk (*) are required.
                            </p>
                        </div>
                        
                        <form
                            className="grid grid-cols-1 md:grid-cols-6 gap-x-6 gap-y-8"
                            onSubmit={handleSubmit(formSubmitHandler)}
                            noValidate
                        >
                            <TextInput
                                className="md:col-span-3"
                                label="First Name"
                                name="firstName"
                                register={register}
                                error={errors.firstName}
                                required={true}
                            />
                            <TextInput
                                className="md:col-span-3"
                                label="Last Name"
                                name="lastName"
                                register={register}
                                error={errors.lastName}
                                required={true}
                            />
                            <TextInput
                                className="md:col-span-3"
                                label="Email"
                                name="email"
                                type="email"
                                register={register}
                                error={errors.email}
                                required={true}
                                pattern={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
                            />
                            <Controller
                                name="phone"
                                control={control}
                                rules={{ required: "Phone number is required!" }}
                                render={({ field }) => (
                                    <FieldWrapper
                                        className="md:col-span-3"
                                        label="Phone"
                                        error={errors.phone}
                                        required={true}
                                    >
                                        <MobileInput
                                            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
                                            {...field}
                                        />
                                    </FieldWrapper>
                                )}
                            />
                            <TextareaField
                                className="md:col-span-6"
                                label="Address"
                                name="address"
                                register={register}
                                error={errors.address}
                                required
                            />
                            
                            <div className="md:col-span-6 border-t border-gray-100 pt-6"></div>
                            
                            <FileInputField
                                className="md:col-span-3"
                                label="Resume (PDF)"
                                name="resume"
                                register={register}
                                error={errors.resume}
                                required
                            />
                            <DateField
                                className="md:col-span-3"
                                label="Date Available"
                                name="dateAvailable"
                                register={register}
                                error={errors.dateAvailable}
                                required
                            />
                            
                            <div className="md:col-span-6 border-t border-gray-100 pt-6"></div>
                            
                            <TextInput
                                className="md:col-span-2"
                                label="Website, Blog or Portfolio"
                                name="portfolioLink"
                                register={register}
                            />
                            <TextInput
                                className="md:col-span-2"
                                label="LinkedIn Profile URL"
                                name="linkedinUrl"
                                register={register}
                            />
                            <TextInput
                                className="md:col-span-2"
                                label="College/University"
                                name="collegeName"
                                register={register}
                            />
                            
                            <div className="md:col-span-6 border-t border-gray-100 pt-6"></div>
                            
                            <div className="md:col-span-6 flex flex-col sm:flex-row gap-4">
                                <motion.button
                                    type="submit"
                                    disabled={isSubmitting}
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    className={`px-8 py-3 rounded-full font-medium text-white ${
                                        isSubmitting 
                                        ? "bg-gray-400 cursor-not-allowed" 
                                        : "bg-gradient-to-r from-cblue to-cgreen hover:shadow-md"
                                    } transition-all duration-300`}
                                >
                                    {isSubmitting ? (
                                        <span className="flex items-center justify-center">
                                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            Submitting...
                                        </span>
                                    ) : "Submit Application"}
                                </motion.button>
                                <motion.button
                                    type="button"
                                    onClick={onCancel}
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    className="px-8 py-3 rounded-full font-medium text-gray-700 border-2 border-gray-300 hover:border-gray-400 transition-all duration-300"
                                >
                                    Cancel
                                </motion.button>
                            </div>
                            
                            <div className="md:col-span-6 text-sm text-gray-500">
                                This site is protected by reCAPTCHA and the Google 
                                <a className="text-blue-600 hover:text-blue-800 mx-1" href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
                                    Privacy Policy
                                </a> 
                                and
                                <a className="text-blue-600 hover:text-blue-800 mx-1" href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
                                    Terms of Service
                                </a> 
                                apply.
                            </div>
                        </form>
                    </motion.div>
                ) : (
                    <ApplicationSuccessFail response={applicationResponse}/>
                )}
            </AnimatePresence>
        </div>
    );
};

const ApplicationSuccessFail = ({response}) => {
    return (
        <motion.div 
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            className="py-12 px-4 flex flex-col items-center justify-center text-center"
        >
            <div className="w-20 h-20 bg-green-100 rounded-full flex items-center justify-center mb-6">
                <FaCheck className="text-green-600 text-3xl" />
            </div>
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">
                Application Submitted!
            </h2>
            <p className="text-lg text-gray-600 mb-6 max-w-lg">
                Your application has been received successfully. We'll review your qualifications and get back to you soon.
            </p>
            <div className="bg-blue-50 rounded-lg p-4 mb-8">
                <p className="text-gray-600">Application ID:</p>
                <p className="text-xl font-semibold text-blue-700">{response.data['application_id']}</p>
            </div>
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => window.location.href = '/careers'}
                className="px-8 py-3 bg-gradient-to-r from-cblue to-cgreen text-white font-medium rounded-full hover:shadow-md transition-all duration-300"
            >
                Browse More Opportunities
            </motion.button>
        </motion.div>
    );
}

export const FieldWrapper = ({ label, children, error, className = "", required = false }) => (
    <div className={`${className} flex flex-col`}>
        <label className="mb-2 text-gray-700 font-medium">
            {label}{required && <span className="text-red-500 ml-1">*</span>}
        </label>
        {children}
        {error && (
            <span className="mt-1 text-red-500 text-sm">
                {error.message}
            </span>
        )}
    </div>
);

const TextInput = ({
    label,
    name,
    type = "text",
    register,
    required,
    error,
    className = "",
    pattern,
}) => (
    <FieldWrapper
        className={className}
        label={label}
        error={error}
        required={required}
    >
        <input
            type={type}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
            {...register(name, {
                ...(required && { required: `${label} is required!` }),
                ...(pattern && {
                    pattern: {
                        value: pattern,
                        message: `Invalid ${label.toLowerCase()} format!`,
                    },
                }),
            })}
        />
    </FieldWrapper>
);

const TextareaField = ({
    label,
    name,
    register,
    required,
    error,
    className = "",
}) => (
    <FieldWrapper
        className={className}
        label={label}
        error={error}
        required={required}
    >
        <textarea
            className="w-full p-3 border border-gray-300 rounded-lg h-28 resize-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
            {...register(
                name,
                required && { required: `${label} is required!` }
            )}
        />
    </FieldWrapper>
);

const FileInputField = ({
    label,
    name,
    register,
    required,
    error,
    className = "",
}) => (
    <FieldWrapper
        className={className}
        label={label}
        error={error}
        required={required}
    >
        <div className="relative">
            <input
                type="file"
                id={`file-${name}`}
                accept=".pdf"
                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer z-10"
                {...register(
                    name,
                    required && { required: `${label} is required!` }
                )}
            />
            <label 
                htmlFor={`file-${name}`} 
                className="flex items-center gap-2 p-3 border border-gray-300 rounded-lg text-gray-500 hover:border-blue-400 cursor-pointer transition-all"
            >
                <FaRegFilePdf className="text-red-500" />
                <span className="truncate">Choose PDF file...</span>
            </label>
        </div>
    </FieldWrapper>
);

const DateField = ({
    label,
    name,
    register,
    required,
    error,
    className = "",
}) => (
    <FieldWrapper
        className={className}
        label={label}
        error={error}
        required={required}
    >
        <input
            type="date"
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
            {...register(
                name,
                required && { required: `${label} is required!` }
            )}
        />
    </FieldWrapper>
);


const SocialShare = ({ jobUrl, jobTitle }) => {
    const encodedUrl = encodeURIComponent(jobUrl);
    const encodedTitle = encodeURIComponent(jobTitle);

    const shareLinks = {
        linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`,
        reddit: `https://www.reddit.com/submit?url=${encodedUrl}&title=${encodedTitle}`,
        twitter: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`,
    };

    return (
        <div className="flex items-center justify-center gap-2">
            <motion.a
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                href={shareLinks.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="w-10 h-10 bg-[#0077B5] text-white rounded-full flex items-center justify-center shadow-sm hover:shadow-md transition-all"
                aria-label="Share on LinkedIn"
            >
                <FaLinkedin className="text-lg" />
            </motion.a>
            <motion.a
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                href={shareLinks.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className="w-10 h-10 bg-black text-white rounded-full flex items-center justify-center shadow-sm hover:shadow-md transition-all"
                aria-label="Share on Twitter"
            >
                <FaXTwitter className="text-lg" />
            </motion.a>
            <motion.a
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                href={shareLinks.reddit}
                target="_blank"
                rel="noopener noreferrer"
                className="w-10 h-10 bg-[#FF4500] text-white rounded-full flex items-center justify-center shadow-sm hover:shadow-md transition-all"
                aria-label="Share on Reddit"
            >
                <FaReddit className="text-lg" />
            </motion.a>
        </div>
    );
};


const CopyToClipboardLink = ({ link }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(link).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        });
    };

    return (
        <div className="flex items-center gap-2 p-2 bg-white rounded-lg border border-gray-200">
            <div className="truncate flex-1 text-sm text-gray-600">
                {link.length > 40 ? `${link.substring(0, 40)}...` : link}
            </div>
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleCopy}
                className={`flex items-center gap-1 py-1 px-3 rounded-full text-sm ${
                    copied 
                    ? "bg-green-100 text-green-700" 
                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                } transition-colors`}
                aria-label="Copy link"
            >
                {copied ? (
                    <>
                        <FaCheck className="text-green-600" />
                        <span>Copied</span>
                    </>
                ) : (
                    <>
                        <FaRegCopy />
                        <span>Copy</span>
                    </>
                )}
            </motion.button>
        </div>
    );
};