import React, { useEffect, useState } from 'react';
import AnimationWrapper from '../components/AnimationWrapper.component';
import GradientText from '../components/GradientText.component';
import { motion } from 'framer-motion';

function PrivacyPolicy() {
    const [htmlContent, setHtmlContent] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Set page title
        document.title = "Privacy Policy | QUBITWARE";
        
        // Fetch the HTML content from the server
        fetch('/privacy_policy.html')
            .then(response => response.text())
            .then(data => {
                setHtmlContent(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching HTML:', error);
                setLoading(false);
            });
    }, []);

    // Animation variants
    const fadeIn = {
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0, transition: { duration: 0.6 } }
    };

    return (
        <AnimationWrapper className="flex flex-col min-h-screen">
            {/* Header section */}
            <section className="relative bg-gradient-to-br from-white to-blue-50 py-16">
                <div className="absolute top-0 right-0 w-1/3 h-1/3 bg-gradient-to-br from-blue-400/5 to-indigo-500/10 rounded-bl-full"></div>
                <div className="absolute bottom-0 left-0 w-1/4 h-1/4 bg-gradient-to-tr from-blue-400/5 to-indigo-500/10 rounded-tr-full"></div>
                
                <div className="container mx-auto px-4 text-center relative z-10">
                    <motion.h1 
                        variants={fadeIn}
                        className="text-4xl md:text-5xl font-bold mb-4"
                    >
                        <GradientText>Privacy Policy</GradientText>
                    </motion.h1>
                    <motion.p 
                        variants={fadeIn}
                        className="text-lg text-gray-600 max-w-2xl mx-auto"
                    >
                        At QUBITWARE, we are committed to protecting your privacy and ensuring the security of your personal information.
                    </motion.p>
                </div>
            </section>

            {/* Content section */}
            <section className="flex-grow bg-white py-8">
                <div className="container mx-auto px-4">
                    {loading ? (
                        <div className="flex justify-center items-center py-20">
                            <div className="w-12 h-12 border-4 border-cblue border-t-transparent rounded-full animate-spin"></div>
                        </div>
                    ) : (
                        <div className="bg-white rounded-lg shadow-sm p-8 prose prose-lg max-w-none" dangerouslySetInnerHTML={{ __html: htmlContent }} />
                    )}
                </div>
            </section>
        </AnimationWrapper>
    );
}

export default PrivacyPolicy;
