import React, { useEffect, useRef } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import { motion } from "framer-motion"; // Assuming framer-motion is installed
import GradientText from "../components/GradientText.component";
import GradientContactCard from "../components/GradientContactCard.component";
import ImageBanner from "../components/ImageSlider.component";

export default function Service() {
    const navigate = useNavigate();
    const service_data = useLoaderData();
    const featuresRef = useRef(null);
    
    const generateBannerImagesList = () => {
        const imagesList = [];
        service_data.images_list.forEach((img) => {
            imagesList.push(`/service_imgs/${img}`);
        });
        return imagesList;
    }

    const scrollToFeatures = () => {
        featuresRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        document.title = `${service_data.title} | QUBITWARE`;
        window.scrollTo(0, 0);
    }, [service_data]);

    // Animation variants
    const fadeIn = {
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0, transition: { duration: 0.6 } }
    };
    
    const staggerChildren = {
        animate: {
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    return (
        <div className="flex flex-col w-full">
            {/* Hero section */}
            <section className="relative overflow-hidden bg-gradient-to-br from-gray-50 to-blue-50">
                <div className="absolute inset-0 bg-grid-pattern opacity-10"></div>
                <div className="container mx-auto grid grid-cols-1 py-20 gap-8 px-4
                    md:gap-12
                    2xl:grid-cols-2 2xl:py-28
                ">
                    {/* Left container */}
                    <motion.div 
                        initial="initial"
                        animate="animate"
                        variants={staggerChildren}
                        className="flex flex-col justify-center items-start gap-6 text-left z-10
                            2xl:gap-8       
                        "
                    >
                        <motion.div variants={fadeIn} className="inline-block">
                            <span className="bg-blue-100 text-blue-700 py-1 px-4 rounded-full text-sm font-medium">
                                Our Services
                            </span>
                        </motion.div>
                        
                        <motion.div variants={fadeIn}>
                            <GradientText
                                className="text-5xl font-extrabold leading-tight
                                    2xl:text-7xl                        
                                "
                                children={service_data.title}
                            />
                        </motion.div>
                        
                        <motion.p 
                            variants={fadeIn}
                            className="text-xl text-gray-600 max-w-2xl"
                        >
                            {service_data.short_description}
                        </motion.p>

                        <motion.div variants={fadeIn} className="flex flex-col sm:flex-row gap-4">
                            <button
                                onClick={() => navigate(`/contact-us`)}
                                className="py-3 px-6 text-lg text-white font-bold bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 rounded-lg shadow-md hover:shadow-lg transition-all duration-300"
                            >
                                Request Consultation
                            </button>
                            
                            <button
                                onClick={scrollToFeatures}
                                className="py-3 px-6 text-lg text-blue-700 font-bold bg-white border border-blue-200 hover:bg-blue-50 rounded-lg shadow-sm hover:shadow-md transition-all duration-300"
                            >
                                Learn More
                            </button>
                        </motion.div>
                    </motion.div>
                    
                    {/* Right Container */}
                    <motion.div 
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.6, delay: 0.2 }}
                        className="col-span-1 h-[450px] row-start-1 rounded-xl overflow-hidden shadow-2xl
                            xl:col-start-2
                        "
                    >
                        <ImageBanner images={generateBannerImagesList()} />
                    </motion.div>
                </div>
                
                <div className="w-full h-24 bg-white transform -skew-y-3 -mb-10"></div>
            </section>

            {/* Service tagline */}
            <section className="py-16 bg-white">
                <motion.div 
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    viewport={{ once: true }}
                    className="container mx-auto px-4"
                >
                    <div className="w-full overflow-hidden bg-gradient-to-r from-blue-600 to-indigo-600 rounded-2xl relative shadow-lg">
                        <div className="absolute right-0 bottom-0 w-64 h-64 bg-white/10 rounded-full -mr-32 -mb-32"></div>
                        <div className="absolute left-0 top-0 w-24 h-24 bg-white/10 rounded-full -ml-12 -mt-12"></div>
                        
                        <div className="px-8 py-12 md:py-16 md:px-16 relative z-10">
                            <h2 className="text-2xl md:text-3xl font-bold text-white leading-relaxed max-w-4xl mx-auto text-center">
                                {service_data.highlighting_text}
                            </h2>
                        </div>
                    </div>
                </motion.div>
            </section>

            {/* Service Features section */}
            <section ref={featuresRef} className="py-20 bg-gray-50">
                <div className="container mx-auto px-4 flex flex-col py-12 gap-8 text-left">
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                        viewport={{ once: true }}
                        className="text-center mb-8"
                    >
                        <h2 className="text-4xl font-bold mb-4">
                            <GradientText children={"Key Advantages"} /> of QUBITWARE's {service_data.title}
                        </h2>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            Discover how our specialized solutions can transform your business operations and drive growth
                        </p>
                    </motion.div>

                    {/* Features */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {service_data.service_features.map((feature, index) => {
                            const title = feature.substring(0, feature.indexOf(':'));
                            const description = feature.substring(feature.indexOf(':') + 1);
                            
                            return (
                                <motion.div 
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: index * 0.1 }}
                                    viewport={{ once: true }}
                                    className="bg-white p-8 rounded-xl shadow-md hover:shadow-lg transition-all"
                                >
                                    <div className="flex flex-col gap-4">
                                        <div className="flex items-center gap-3">
                                            <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center">
                                                <div className="w-5 h-5 bg-gradient-to-br from-blue-500 to-indigo-600 rounded-full"></div>
                                            </div>
                                            <h3 className="text-xl font-bold text-gray-800">
                                                {title}
                                            </h3>
                                        </div>
                                        <p className="text-gray-600 pl-13">
                                            {description}
                                        </p>
                                    </div>
                                </motion.div>
                            );
                        })}
                    </div>
                </div>
            </section>

            {/* Why section */}
            <section className="py-20 bg-white">
                <div className="container mx-auto px-4 grid grid-cols-1 gap-8 items-center
                    lg:grid-cols-5
                ">
                    {/* left container - image */}
                    <motion.div 
                        initial={{ opacity: 0, x: -30 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        viewport={{ once: true }}
                        className="lg:col-span-2 w-full h-96 lg:h-[500px] relative rounded-3xl overflow-hidden shadow-xl"
                    >
                        <div 
                            className="absolute inset-0 bg-cover bg-center"
                            style={{ backgroundImage: `url(https://images.unsplash.com/photo-1536104968055-4d61aa56f46a?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)` }}
                        >
                            <div className="h-full w-full bg-gradient-to-br from-blue-900/70 to-indigo-800/70">
                                <div className="absolute bottom-0 left-0 w-full p-8">
                                    <div className="flex items-center gap-2">
                                        <div className="w-3 h-3 bg-green-400 rounded-full"></div>
                                        <div className="text-white font-medium">Trusted Technology Partner</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>

                    {/* right container - content */}
                    <motion.div 
                        initial={{ opacity: 0, x: 30 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.6, delay: 0.2 }}
                        viewport={{ once: true }}
                        className="lg:col-span-3 flex flex-col justify-center items-start gap-6 text-left p-4 lg:p-8"
                    >
                        <GradientText
                            className="text-4xl lg:text-5xl font-bold"
                            children="Why QUBITWARE?"
                        />
                        
                        <div className="w-20 h-1 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-full"></div>
                        
                        <p className="text-xl text-gray-600 leading-relaxed">
                            {service_data.why_service}
                        </p>
                        
                        <div className="mt-4 grid grid-cols-2 gap-4 w-full">
                            <div className="bg-gray-50 p-4 rounded-lg">
                                <div className="text-3xl font-bold text-blue-600">100%</div>
                                <div className="text-gray-600">Client Satisfaction</div>
                            </div>
                            <div className="bg-gray-50 p-4 rounded-lg">
                                <div className="text-3xl font-bold text-blue-600">24/7</div>
                                <div className="text-gray-600">Expert Support</div>
                            </div>
                        </div>
                        
                        <button
                            onClick={() => navigate(`/contact-us`)}
                            className="mt-4 py-3 px-6 text-blue-700 font-bold border border-blue-200 hover:bg-blue-50 rounded-lg shadow-sm hover:shadow-md transition-all duration-300"
                        >
                            Learn How We Can Help
                        </button>
                    </motion.div>
                </div>
            </section>

            {/* Process Section - New section */}
            <section className="py-20 bg-gray-50">
                <div className="container mx-auto px-4">
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                        viewport={{ once: true }}
                        className="text-center mb-16"
                    >
                        <h2 className="text-4xl font-bold mb-4">
                            Our <GradientText children={"Process"} />
                        </h2>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            How we deliver exceptional {service_data.title} solutions
                        </p>
                    </motion.div>
                    
                    <div className="relative">
                        {/* Timeline line */}
                        <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-gradient-to-b from-blue-500 to-indigo-600 hidden md:block"></div>
                        
                        {/* Process steps */}
                        <div className="flex flex-col gap-16">
                            {[
                                { title: "Discovery & Analysis", description: "We begin by understanding your unique challenges, requirements, and goals through comprehensive analysis." },
                                { title: "Strategic Planning", description: "Our experts develop a tailored strategy and solution architecture designed for your specific needs." },
                                { title: "Implementation", description: "We execute the plan with precision, adhering to industry best practices and quality standards." },
                                { title: "Testing & Refinement", description: "Rigorous testing ensures your solution meets all requirements and performs optimally." },
                                { title: "Deployment & Support", description: "After successful deployment, we provide ongoing support to ensure continued success." }
                            ].map((step, index) => (
                                <motion.div 
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: index * 0.1 }}
                                    viewport={{ once: true }}
                                    className={`flex flex-col md:flex-row gap-8 items-center ${
                                        index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
                                    }`}
                                >
                                    {/* Content */}
                                    <div className={`md:w-1/2 flex flex-col items-${index % 2 === 0 ? 'end md:text-right' : 'start md:text-left'} gap-4`}>
                                        <h3 className="text-2xl font-bold text-gray-800">
                                            {step.title}
                                        </h3>
                                        <p className="text-gray-600 max-w-md">
                                            {step.description}
                                        </p>
                                    </div>
                                    
                                    {/* Timeline node */}
                                    <div className="relative flex items-center justify-center w-16 h-16 bg-white rounded-full shadow-md z-10">
                                        <div className="w-10 h-10 bg-gradient-to-br from-blue-500 to-indigo-600 rounded-full flex items-center justify-center text-white font-bold">
                                            {index + 1}
                                        </div>
                                    </div>
                                    
                                    {/* Empty div for layout */}
                                    <div className="md:w-1/2"></div>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            {/* Contact Us card */}
            <section className="py-20 bg-white">
                <motion.div 
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    viewport={{ once: true }}
                    className="container mx-auto px-4"
                >
                    <GradientContactCard
                        message={
                            `Ready to leverage our ${service_data.title} expertise for your business?`
                        }
                    />
                </motion.div>
            </section>
            
            {/* Related Services - New section */}
            {/* <section className="py-20 bg-gray-50">
                <div className="container mx-auto px-4">
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                        viewport={{ once: true }}
                        className="text-center mb-16"
                    >
                        <h2 className="text-4xl font-bold mb-4">
                            Related <GradientText children={"Services"} />
                        </h2>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            Explore other solutions that complement {service_data.title}
                        </p>
                    </motion.div>
                    
                    <div className="flex flex-wrap justify-center gap-6">
                        {[1, 2, 3].map((index) => (
                            <motion.div 
                                key={index}
                                initial={{ opacity: 0, scale: 0.9 }}
                                whileInView={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                                viewport={{ once: true }}
                                whileHover={{ y: -5 }}
                                className="bg-white rounded-xl shadow-md p-6 flex flex-col items-center text-center max-w-xs cursor-pointer hover:shadow-lg transition-all"
                                onClick={() => navigate(`/services/Related Service ${index}`)}
                            >
                                <div className="w-16 h-16 mb-4 flex items-center justify-center bg-blue-100 rounded-lg">
                                    <div className="w-8 h-8 bg-gradient-to-br from-blue-500 to-indigo-600 rounded-lg"></div>
                                </div>
                                <h3 className="text-xl font-bold text-gray-800 mb-2">Related Service {index}</h3>
                                <p className="text-gray-600">
                                    A complementary service that works well with {service_data.title}.
                                </p>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section> */}
        </div>
    );
}
