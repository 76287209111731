import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import GradientText from "../components/GradientText.component";
import ContactForm from "../components/ContactForn.component";
import { IoCallSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaReddit, FaXTwitter, FaLinkedin } from "react-icons/fa6";
import Footer from "../components/Footer.component";
import FAQ from "../components/Faq.component";
import ScrollToSection from '../utils/ScrollToSection'

const faqData = [
    {
        question: "What is Qubitware?",
        answer:
            "Qubitware IT Solutions LLP is a global IT services and consulting company specializing in a broad spectrum of technology solutions designed to enhance business operations and drive digital transformation. The company provides end-to-end services that include cloud computing, cybersecurity, managed IT services, and advanced AI-driven solutions.",
    },
    {
        question: "What services does Qubitware offer?",
        answer:
            <>
                <strong>Qubitware offers the following services:</strong><br/>
                <ul className="list-disc pl-10">
                    <li>Microsoft 365 Deployment and Management</li>
                    <li>Azure Cloud Services</li>
                    <li>Security Solutions and Compliance</li>
                    <li>Managed IT Services</li>
                    <li>Device Management</li>
                    <li>Virtual Desktop Infrastructure (VDI) Solutions</li>
                    <li>Microsoft Teams Consulting</li>
                    <li>Data Backup and Recovery</li>
                    <li>IT Support and Helpdesk Services</li>
                    <li>Training and Workshops</li>
                    <li>Network Design and Implementation</li>
                    <li>IT Consulting and Strategy Planning</li>
                    <li>Software Licensing and Asset Management</li>
                    <li>Cybersecurity Training and Awareness</li>
                    <li>Full Stack Development</li>
                    <li>Custom Software Development</li>
                    <li>Data Science and Analytics</li>
                    <li>Chatbot Development</li>
                    <li>Business Intelligence Solutions</li>
                    <li>Robotic Process Automation (RPA)</li>
                    <li>Natural Language Processing (NLP)</li>
                    <li>Computer Vision Solutions</li>
                    <li>AI-Powered Personalization</li>
                    <li>AI Consultation and Strategy</li>
                </ul>
            </>,
    },
    {
        question: "How can I contact Qubitware?",
        answer:
            "You can reach out to us via email, phone or by filling the contact us form (helpdesk@qubitware.in phone: +91 9354407238)",
    },
    {
        question: "How can I request a quote from Qubitware?",
        answer:
            "You can request a quote from Qubitware by filling out the contact form on our website or by contacting us directly. Our team will get back to you with a customized quote based on your requirements.",
    },
    {
        question: "Does Qubitware offer custom solutions?",
        answer:
            "Yes, Qubitware offers custom solutions tailored to your business needs. Our team of experts will work with you to create a website or mobile application that meets your specific requirements and goals.",
    },
];

// Animation variants
const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const staggerChildren = {
    animate: {
        transition: {
            staggerChildren: 0.1
        }
    }
};

export default function ContactUs() {
    const [activeTab, setActiveTab] = useState('general');
    
    useEffect(() => {
        document.title = "Contact Us | QUBITWARE";
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className="flex flex-col gap-12 bg-gray-50">
            <ScrollToSection/>
            
            {/* Hero Section - New */}
            <section className="bg-gradient-to-br from-blue-900 via-indigo-800 to-purple-900 text-white py-20">
                <div className="container mx-auto px-4 text-center">
                    <motion.div 
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        className="max-w-3xl mx-auto"
                    >
                        <h1 className="text-5xl md:text-6xl font-extrabold mb-6">
                            Let's Start a <GradientText className="bg-gradient-to-r from-blue-300 to-purple-300">Conversation</GradientText>
                        </h1>
                        <p className="text-xl text-gray-200 mb-10">
                            Have questions about our services? Looking for a technology partner? 
                            We're here to help you navigate the digital landscape.
                        </p>
                    </motion.div>
                </div>
                
                {/* Wave divider */}
                {/* <div className="w-full overflow-hidden">
                    <svg preserveAspectRatio="none" viewBox="0 0 1200 120" xmlns="http://www.w3.org/2000/svg" 
                         className="w-full h-20 text-gray-50">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" 
                              fill="currentColor"></path>
                    </svg>
                </div> */}
            </section>
            
            {/* contact form */}
            <section
                id="contact-us-section"
                className="py-12 -mt-12">
                <div className="container mx-auto grid grid-cols-1 gap-8 max-w-6xl px-4">
                    <motion.div 
                        initial="initial"
                        animate="animate"
                        variants={staggerChildren}
                        className="flex flex-col gap-4 text-center"
                    >
                        <motion.div variants={fadeIn}>
                            <GradientText className="text-4xl font-bold mx-auto 2xl:text-5xl">
                                Get in Touch
                            </GradientText>
                        </motion.div>
                        <motion.h2 
                            variants={fadeIn}
                            className="text-lg text-slate-500 md:text-xl max-w-2xl mx-auto"
                        >
                            Whether you have a question about our services, pricing, or anything else, our team is ready to answer all your questions.
                        </motion.h2>
                    </motion.div>
                    
                    {/* Contact Form Tabs - New */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                        className="flex flex-wrap justify-center gap-2 mb-6"
                    >
                        {[
                            { id: 'general', label: 'General Inquiry' },
                            { id: 'support', label: 'Technical Support' },
                            { id: 'quote', label: 'Request a Quote' }
                        ].map((tab) => (
                            <button
                                key={tab.id}
                                onClick={() => setActiveTab(tab.id)}
                                className={`px-6 py-2 rounded-full text-sm font-medium transition-all ${
                                    activeTab === tab.id 
                                        ? 'bg-blue-600 text-white shadow-md' 
                                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                                }`}
                            >
                                {tab.label}
                            </button>
                        ))}
                    </motion.div>
                    
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                        className="grid grid-cols-1 justify-between shadow-xl rounded-2xl bg-white overflow-hidden
                            lg:grid-cols-3
                        "
                    >
                        {/* left container */}
                        <div className="bg-gradient-to-br from-blue-600 to-indigo-700 text-white p-8 text-left flex flex-col justify-between gap-8
                            md:p-10
                        ">
                            {/* Heading */}
                            <div>
                                <h2 className="text-3xl font-bold mb-3">
                                    Contact Information
                                </h2>
                                <p className="text-blue-100">
                                    Reach out directly or fill out the form and we'll get back to you promptly.
                                </p>
                            </div>

                            {/* Contact Details */}
                            <div className="flex flex-col gap-6 text-md font-base text-gray-100">
                                {[
                                    {
                                        icon: <IoCallSharp className="size-5 text-blue-200" />,
                                        text: "+91 9354407238",
                                        link: "tel:+919354407238"
                                    },
                                    {
                                        icon: <MdEmail className="size-5 text-blue-200" />,
                                        text: "helpdesk@qubitware.in",
                                        link: "mailto:helpdesk@qubitware.in"
                                    },
                                    {
                                        icon: <FaLocationDot className="size-5 text-blue-200" />,
                                        text: (
                                            <>
                                                Jaipur -302034
                                                Rajasthan, IN
                                            </>
                                        ),
                                        link: "https://maps.google.com/?q=Bishanawala,Jaipur,Rajasthan,302034"
                                    }
                                ].map((item, index) => (
                                    <a 
                                        href={item.link}
                                        key={index} 
                                        className="flex items-start gap-4 p-3 rounded-lg hover:bg-white/10 transition-colors"
                                    >
                                        <div className="p-2 bg-white/10 rounded-full">{item.icon}</div>
                                        <span>{item.text}</span>
                                    </a>
                                ))}
                            </div>
                            
                            {/* Social Links */}
                            <div>
                                <h3 className="text-sm font-medium text-blue-200 mb-3">CONNECT WITH US</h3>
                                <div className="flex gap-4">
                                    {[
                                        { icon: <FaXTwitter className="w-5 h-5" />, link: "https://x.com/technologi21594" },
                                        { icon: <FaLinkedin className="w-5 h-5" />, link: "https://www.linkedin.com/company/jmr-technologies-in/" }
                                    ].map((item, index) => (
                                        <a target="_blank"
                                            rel="noopener noreferrer" 
                                            href={item.link}
                                            key={index} 
                                            className="p-3 bg-white/10 rounded-full hover:bg-white/20 transition-all">
                                            {item.icon}
                                        </a>
                                    ))}
                                </div>
                            </div>
                            
                            {/* Decorative Elements */}
                            <div className="absolute bottom-0 right-0 w-40 h-40 bg-white/5 rounded-full -mr-20 -mb-20"></div>
                            <div className="absolute top-0 left-0 w-20 h-20 bg-white/5 rounded-full -ml-10 -mt-10"></div>
                        </div>

                        {/* Right container - Form */}
                        <div className="lg:col-span-2 p-6 md:p-10">
                            <ContactForm 
                                formClasses="py-4"
                                formType={activeTab}
                            />
                        </div>
                    </motion.div>
                </div>
            </section>
            
            {/* Map Section - New */}
            <section className="py-12 bg-white">
                <div className="container mx-auto px-4">
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                        className="rounded-xl overflow-hidden shadow-lg h-96"
                    >
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.121136915903!2d75.7885!3d26.8696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5996e3b6117%3A0xf376832dbe19d37!2sJaipur%2C%20Rajasthan%2C%20India!5e0!3m2!1sen!2sin!4v1624096065951!5m2!1sen!2sin" 
                            width="100%" 
                            height="100%" 
                            style={{ border: 0 }} 
                            allowFullScreen="" 
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade"
                            title="Qubitware Location"
                        ></iframe>
                    </motion.div>
                </div>
            </section>
            
            {/* FAQ section - Enhanced */}
            <section id="faq-section" className="py-16 bg-gray-50">
                <div className="container mx-auto px-4 max-w-5xl">
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                        className="text-center mb-12"
                    >
                        <GradientText className="text-4xl font-bold mb-4">
                            Frequently Asked Questions
                        </GradientText>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            Find answers to common questions about Qubitware and our services
                        </p>
                    </motion.div>
                    
                    <motion.div
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, delay: 0.2 }}
                    >
                        <FAQ faqData={faqData} />
                    </motion.div>
                </div>
            </section>
            
            {/* Call to Action Section - New */}
            <section className="py-20 bg-white">
                <div className="container mx-auto px-4">
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                        className="max-w-4xl mx-auto text-center"
                    >
                        <h2 className="text-3xl font-bold mb-6">
                            Ready to Get Started with <GradientText>Qubitware</GradientText>?
                        </h2>
                        <p className="text-xl text-gray-600 mb-10">
                            Our team of experts is ready to help you navigate your digital transformation journey.
                        </p>
                        <div className="flex flex-col sm:flex-row gap-4 justify-center">
                            <a 
                                href="#contact-us-section" 
                                className="bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 text-white px-8 py-4 rounded-lg font-bold text-lg transition-all shadow-md hover:shadow-lg"
                            >
                                Contact Us Now
                            </a>
                            <a 
                                href="tel:+919354407238" 
                                className="bg-white text-blue-700 hover:bg-blue-50 border border-blue-200 px-8 py-4 rounded-lg font-bold text-lg transition-all shadow-sm hover:shadow-md"
                            >
                                Call Directly
                            </a>
                        </div>
                    </motion.div>
                </div>
            </section>
            
            <Footer varient="blue" />
        </div>
    );
}
