import { useNavigate } from "react-router-dom";
import Timestamp from "react-timestamp";
import { motion } from "framer-motion";
import Tag from "./Tag.component";

export default function BlogCard(props) {
    const {
        title,
        id,
        short_description,
        banner,
        created_at,
        read_time,
        tags,
    } = props?.details || {};
    console.log(tags);
    const navigate = useNavigate();

    const blogTagClickHandler = (tag, isSelected) => {
        // Prevent parent click event
        // event.stopPropagation();
        navigate(`/blogs?tag=${tag}`);
    };

    const blogCardClickHandler = () => {
        navigate("/blogs/" + id + "/" + title);
    };

    // Animation variants
    const cardVariants = {
        hover: {
            scale: 1.02,
            transition: {
                duration: 0.2,
                ease: "easeInOut"
            }
        }
    };


    return (
        <motion.div 
            onClick={blogCardClickHandler} 
            className="cursor-pointer group hover:bg-blue-50 transition-colors duration-300 rounded-lg p-2"
            whileHover="hover"
            variants={cardVariants}
        >
            <div className="grid grid-cols-3 p-4 gap-4 relative">
                {/* Left container */}
                <div className="flex flex-col text-start col-span-2 gap-2
                    md:gap-4
                ">
                    <div className="flex flex-wrap gap-2">
                        {tags && tags?.length > 0 && tags?.slice(0, 2)?.map((tag, index) => (
                            <Tag 
                                key={index}
                                className="text-xs
                                    md:text-sm
                                    xl:text-base
                                " 
                                name={tag} 
                                onClickHandler={blogTagClickHandler} 
                            />
                        ))}
                        {tags && tags?.length > 2 && (
                            <span className="text-xs text-gray-500 md:text-sm self-center">+{tags?.length - 2} more</span>
                        )}
                    </div>
                    
                    <div className="space-y-2">
                        <h2 className="line-clamp-2 font-extrabold text-xl group-hover:text-blue-700 transition-colors duration-300
                            md:text-2xl
                            xl:text-3xl
                        ">
                            {title}
                        </h2>
                        <p className="line-clamp-3 text-sm text-gray-600 group-hover:text-gray-700 transition-colors
                            md:text-base
                            xl:text-xl xl:line-clamp-3
                        ">
                            {short_description}
                        </p>
                    </div>
                    
                    <div className="flex items-center gap-3 text-xs text-gray-500 
                        md:text-sm
                    ">
                        <Timestamp
                            className="capitalize"
                            relative
                            date={created_at}
                        />
                        <span className="w-1 h-1 rounded-full bg-gray-400"></span>
                        <span className="flex items-center">
                            <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            {read_time} min read
                        </span>
                    </div>
                </div>

                {/* Right Container */}
                <div className="relative overflow-hidden rounded-lg flex justify-center items-center max-w-32 grow justify-self-center
                    xl:max-w-36
                ">
                    <div className="absolute inset-0 bg-gradient-to-br from-blue-400/30 to-purple-600/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10"></div>
                    <img
                        src={banner}
                        alt={`Image for ${title}`}
                        className="w-full aspect-square object-cover object-center bg-slate-100 transition-transform duration-500 group-hover:scale-110"
                    />
                </div>
            </div>
            
            {/* Read more indicator */}
            <div className="flex justify-end pr-4 pb-2">
                <span className="text-xs text-blue-600 font-medium opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center">
                    Read more
                    <svg className="w-3 h-3 ml-1 transform group-hover:translate-x-1 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                </span>
            </div>
        </motion.div>
    );
}
