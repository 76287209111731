/* global grecaptcha */
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Input from "./Input.component";
import Alert from "./Alert.component";
import axios from "axios";
import Cookies from "js-cookie";
import { FiSend, FiUser, FiMail, FiPhone, FiMessageSquare } from "react-icons/fi";

export default function ContactForm({ formClasses = "", theme = "light" }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        message: "",
    });
    const [formSubmissionStatus, setFormSubmissionStatus] = useState(null);

    // Define input fields with icons and improved validation
    const inputFields = [
        {
            label: "First Name",
            name: "firstName",
            required: true,
            pattern: "[A-Za-z]{2,}",
            icon: <FiUser className="text-gray-400" />,
            title: "Please enter a valid first name (minimum 2 letters)",
        },
        {
            label: "Last Name",
            name: "lastName",
            required: true,
            pattern: "[A-Za-z]{2,}",
            icon: <FiUser className="text-gray-400" />,
            title: "Please enter a valid last name (minimum 2 letters)",
        },
        {
            label: "Email",
            name: "email",
            required: true,
            type: "email",
            icon: <FiMail className="text-gray-400" />,
            title: "Please enter a valid email address",
        },
        {
            label: "Mobile",
            name: "mobile",
            required: true,
            type: "tel",
            pattern: "[0-9]{10}",
            icon: <FiPhone className="text-gray-400" />,
            title: "Please enter a valid 10-digit mobile number without country code",
        },
    ];

    const handleShowAlert = () => {
        setShowAlert(true);
        setIsSubmitting(false);
        resetForm();
    };

    const handleAlertClose = () => {
        setShowAlert(false);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const resetForm = () => {
        setFormData({
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            message: "",
        });
    };

    const submitFormData = async (recaptchaToken) => {
        const csrfToken = Cookies.get("csrftoken");
        const request_payload = {
            ...formData,
            reCaptcha_token: recaptchaToken,
        };
        const alertMessageList = {
            201: {
                status: "success",
                alertMessage: "Message sent successfully!",
            },
            409: {
                status: "success",
                alertMessage: "Message sent successfully!",
            },
            405: {
                status: "error",
                alertMessage: "Unexpected error occurred!",
            },
            400: {
                status: "error",
                alertMessage: "Invalid form data!",
            },
            403: {
                status: "error",
                alertMessage:
                    "Message not sent... Failed reCaptcha validation!",
            },
        };

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/contacts/add-contact/`,
                request_payload,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrfToken,
                    },
                }
            );
            setFormSubmissionStatus(alertMessageList[res.status]);
        } catch (err) {
            setFormSubmissionStatus(
                alertMessageList[err?.response?.status] || {
                    status: "error",
                    alertMessage: "Could not send message. Please try again later.",
                }
            );
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        
        try {
            await grecaptcha.enterprise.ready(async () => {
                const reCaptcha_token = await grecaptcha.enterprise.execute(
                    process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
                    { action: "SUBMIT_CONTACT_US_FORM" }
                );
                await submitFormData(reCaptcha_token);
                handleShowAlert();
            });
        } catch (error) {
            console.error("reCAPTCHA error:", error);
            setFormSubmissionStatus({
                status: "error",
                alertMessage: "Error with reCAPTCHA verification. Please try again.",
            });
            handleShowAlert();
        }
    };

    const isDarkTheme = theme === "dark";
    
    // Animation variants
    const formAnimation = {
        hidden: { opacity: 0, y: 20 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: {
                duration: 0.6,
                staggerChildren: 0.1,
                delayChildren: 0.2
            }
        }
    };
    
    const itemAnimation = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    };
    
    return (
        <motion.form
            initial="hidden"
            animate="visible"
            variants={formAnimation}
            noValidate
            id="contactForm"
            className={`group ${formClasses} ${isDarkTheme ? 'text-white' : 'text-gray-800'}`}
            onSubmit={handleSubmit}
        >
            <div className={`grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 p-4 md:p-6 rounded-xl ${
                isDarkTheme 
                    ? 'bg-gray-800 bg-opacity-50' 
                    : 'bg-white shadow-lg shadow-blue-100/30'
            }`}>
                {inputFields.map((inputField) => (
                    <motion.div 
                        key={inputField.name}
                        variants={itemAnimation}
                        className={`relative ${inputField.name === 'message' ? 'md:col-span-2' : ''}`}
                    >
                        <Input
                            {...inputField}
                            value={formData[inputField.name]}
                            onChange={handleChange}
                            icon={inputField.icon}
                            theme={theme}
                        />
                    </motion.div>
                ))}
                
                {/* Message */}
                <motion.div 
                    variants={itemAnimation}
                    className="flex md:col-span-2 flex-col text-start font-semibold"
                >
                    <div className="relative">
                        <div className="absolute top-2 left-0">
                            <FiMessageSquare className={isDarkTheme ? "text-gray-400" : "text-gray-400"} />
                        </div>
                        <textarea
                            name="message"
                            className={`w-full order-2 p-2 pl-7 text-sm ${
                                isDarkTheme 
                                    ? 'bg-transparent text-white border-gray-600 focus:border-blue-400' 
                                    : 'bg-transparent text-gray-800 border-gray-300 focus:border-blue-500'
                            } focus:outline-none border-b-2 h-32 resize-none [&:not(:placeholder-shown)]:border-cblue placeholder:text-gray-400 peer`}
                            placeholder="Write your message..."
                            value={formData.message}
                            onChange={handleChange}
                        ></textarea>
                        <label
                            className={`order-1 text-sm ${
                                isDarkTheme
                                    ? 'text-gray-300 peer-focus:text-blue-400 peer-[&:not(:placeholder-shown)]:text-blue-400'
                                    : 'text-gray-500 peer-focus:text-cblue peer-[&:not(:placeholder-shown)]:text-cblue'
                            } md:text-base transition-colors`}
                            htmlFor="message"
                        >
                            Message
                        </label>
                    </div>
                </motion.div>
            </div>
            
            <div className="mt-6 flex justify-end">
                <motion.button
                    variants={itemAnimation}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    type="submit"
                    disabled={isSubmitting}
                    className={`flex items-center gap-2 py-2.5 px-6 rounded-full ${
                        isDarkTheme
                            ? 'bg-gradient-to-r from-blue-600 to-indigo-700'
                            : 'bg-gradient-to-r from-cblue to-blue-700'
                    } text-white font-medium shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/30 transition-all duration-300 disabled:opacity-50 disabled:pointer-events-none group-invalid:opacity-50 group-invalid:pointer-events-none`}
                >
                    {isSubmitting ? (
                        <>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <span>Sending...</span>
                        </>
                    ) : (
                        <>
                            <span>Send Message</span>
                            <FiSend className="text-white group-hover:translate-x-1 transition-transform" />
                        </>
                    )}
                </motion.button>
            </div>
            
            <AnimatePresence>
                {showAlert && (
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        className="mt-4"
                    >
                        <Alert
                            type={formSubmissionStatus?.status || "error"}
                            message={formSubmissionStatus?.alertMessage || "An unknown error occurred"}
                            onClose={handleAlertClose}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.form>
    );
}
