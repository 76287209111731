import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import Navbar from './components/Navbar.component';
import Blogs, { blogs_routes } from './pages/Blogs.page';
import Home, { fetchXLatestBlogs } from './pages/Home.page';
import Services, { services_routes } from './pages/Services.page';
import Partners from './pages/customers/Customers';
import ContactUs from './pages/ContactUs.page';
import PrivacyPolicy from './pages/PrivacyPolicy.page';
import ServiceTerms from './pages/ServiceTerms.page';
import NotFound from './pages/not_found/NotFound';
import Careers, {career_routes} from './pages/Careers.page';

function Layout() {
  return (
    <div className="w-screen">
      <Navbar />
      <Outlet />
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />,
        loader: fetchXLatestBlogs,
      },
      {
        path: '/services/*',
        element: <Services />,
        children: services_routes,
      },
      {
        path: '/blogs',
        element: <Blogs />,
        children: blogs_routes,
      },
      {
        path: '/partners',
        element: <Partners />,
      },
      {
        path: '/contact-us',
        element: <ContactUs />,
      },
      {
        path: '/careers',
        element: <Careers />,
        children: career_routes,
      },
    ],
  },
  {
    path: '/privacypolicy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/terms-of-service',
    element: <ServiceTerms />,
  },
  {
    path: '/not-found',
    element: <NotFound />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

export default function App() {
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/set-csrf-cookie/`, {
        withCredentials: true,
      })
      .catch((err) => console.error('Error fetching CSRF token:', err));
  }, []);

  return <RouterProvider router={router} />;
}
