import { useState } from "react";
import axios from "axios";
import GradientText from "../components/GradientText.component";
import GradientBall from "../components/GradientBall.component"
import { FaArrowRight } from "react-icons/fa";
import Footer from "../components/Footer.component";
import { Outlet, useNavigate } from "react-router-dom";
import JobApplication from '../components/JobApplication.component'
import { useLoaderData } from "react-router-dom"
import { motion } from "framer-motion";
import { AnimationWrapper } from "../components/AnimationWrapper.component";
import { MdArrowForward, MdPerson } from "react-icons/md";

const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
};

const staggerContainer = {
    animate: {
        transition: {
            staggerChildren: 0.1
        }
    }
};

const fetchAllDepartmentNames = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/career/departments/`);
        return response.data['departments'];
    } catch (error) {
        console.error(error);
        return [];
    }
};

const fetchAllJobPostings = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/career/jobs/open/`);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

const careerHomeLoader = async () => {
    return {
        departmentsNameList: await fetchAllDepartmentNames(),
        jobPostingsList: await fetchAllJobPostings()
    };
};

const jobApplicationLoader = async ({ params }) => {
    return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/career/jobs/${params.job_id}/`)
        .then(res => {
            return res.data
        })
        .catch(err => {
            console.log(err);
        });
}

export const career_routes = [
    {
        path: '',
        element: <CareerHome />,
        loader: careerHomeLoader
    },
    {
        path: 'jobs/:job_id',
        element: <JobApplication />,
        loader: jobApplicationLoader
    }
];

const Careers = () => {
    return (
        <>
        <Outlet/>
        <Footer varient="blue"/>
        </>
    )
}

function CareerHome() {
    const {departmentsNameList, jobPostingsList} = useLoaderData();
    const [activeSection, setActiveSection] = useState("opportunities");

    const jobCategories = [
        "View all",
        ...departmentsNameList
    ];

    return (
        <div className="flex flex-col text-left">
            <Banner 
                hasJobs={jobPostingsList.length > 0} 
                activeSection={activeSection}
                setActiveSection={setActiveSection}
            />
            
            {activeSection === "opportunities" ? (
                <AllJobsView 
                    jobCategoriesList={jobCategories} 
                    defaultCategory={jobCategories[0]} 
                    jobListings={jobPostingsList} 
                />
            ) : (
                <AboutOurCulture />
            )}
        </div>
    );
};

function Banner({ hasJobs, activeSection, setActiveSection }) {
    const scrollToSection = (section) => {
        const element = document.getElementById(section);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <section className="relative overflow-hidden bg-gradient-to-br from-white to-blue-50">
            <GradientBall />
            <div className="absolute top-0 right-0 w-1/3 h-1/3 bg-gradient-to-br from-blue-400/5 to-indigo-500/10 rounded-bl-full"></div>
            <div className="absolute bottom-0 left-0 w-1/4 h-1/4 bg-gradient-to-tr from-blue-400/5 to-indigo-500/10 rounded-tr-full"></div>
            
            <div className="container mx-auto py-20 md:py-24 flex flex-col gap-y-8 relative z-10">
                <motion.div 
                    initial="initial"
                    animate="animate"
                    variants={staggerContainer}
                    className="flex flex-col gap-y-8"
                >
                    {hasJobs && (
                        <motion.div variants={fadeIn} className="relative max-w-fit p-[3px]">
                            <div className="absolute inset-0 bg-gradient-to-r from-cblue to-cgreen rounded-full"></div>
                            <div className="relative bg-white rounded-full px-4 py-2">
                                <GradientText className='text-base' children={"We're hiring!"} />
                            </div>
                        </motion.div>
                    )}
                    
                    <motion.h1 variants={fadeIn} className="text-5xl md:text-6xl lg:text-7xl font-extrabold">
                        Join our <GradientText>innovative</GradientText> team
                    </motion.h1>
                    
                    <motion.p 
                        variants={fadeIn}
                        className="text-xl text-gray-600 max-w-3xl leading-relaxed"
                    >
                        At Qubitware, we're building the future of digital identity solutions. 
                        We value creativity, collaboration, and the courage to challenge 
                        the status quo.
                    </motion.p>
                    
                    <motion.div 
                        variants={fadeIn}
                        className="flex gap-x-4 pt-4"
                    >
                        <button 
                            onClick={() => setActiveSection("opportunities")}
                            className={`px-8 py-3 font-medium rounded-full transition-all duration-300 ${
                                activeSection === "opportunities" 
                                ? "bg-gradient-to-r from-cblue to-cgreen text-white shadow-lg shadow-blue-300/20" 
                                : "bg-white text-gray-700 border-2 border-gray-200 hover:border-cblue"
                            }`}
                        >
                            Explore opportunities
                        </button>
                        <button 
                            onClick={() => setActiveSection("culture")}
                            className={`px-8 py-3 font-medium rounded-full transition-all duration-300 ${
                                activeSection === "culture" 
                                ? "bg-gradient-to-r from-cblue to-cgreen text-white shadow-lg shadow-blue-300/20" 
                                : "bg-white text-gray-700 border-2 border-gray-200 hover:border-cblue"
                            }`}
                        >
                            About our culture
                        </button>
                    </motion.div>
                </motion.div>
            </div>
            
            {/* Decorative elements */}
            <div className="absolute bottom-0 left-0 w-full">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="w-full h-auto">
                    <path fill="#ffffff" fillOpacity="1" d="M0,96L48,112C96,128,192,160,288,154.7C384,149,480,107,576,101.3C672,96,768,128,864,138.7C960,149,1056,139,1152,122.7C1248,107,1344,85,1392,74.7L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                </svg>
            </div>
        </section>
    );
}

function AboutOurCulture() {
    const cultureValues = [
        {
            icon: "💡",
            title: "Innovation",
            description: "We constantly push boundaries and explore new technologies to create cutting-edge solutions."
        },
        {
            icon: "🤝",
            title: "Collaboration",
            description: "We believe great ideas emerge when diverse minds work together in an environment of mutual respect."
        },
        {
            icon: "🚀",
            title: "Excellence",
            description: "We strive for excellence in everything we do, from code quality to customer relationships."
        },
        {
            icon: "🌱",
            title: "Growth",
            description: "We invest in continuous learning and provide opportunities for both personal and professional development."
        }
    ];

    const employeeTestimonial = [{
        name: "Rahul Kumar",
        designation: "AI Engineer",
        testimonial: "Joining Qubitware was the best career decision I've made. The culture of innovation, combined with supportive leadership and challenging projects, has helped me grow tremendously as a professional. The collaborative environment encourages creative thinking, while the mentorship opportunities have accelerated my learning journey in AI technologies. I'm proud to be part of a team that's pushing the boundaries of what's possible."
    },
    {
        name: "Praveen Kumar",
        designation: "Full Stack Developer",
        testimonial: "Working at Qubitware has been transformative for my career. The collaborative culture fosters creativity, while the cutting-edge projects challenge me to expand my technical horizons. I've found not just colleagues here, but mentors who genuinely care about my growth journey."
    },
    
    ]

    return (
        <section id="about-culture" className="py-24 bg-gradient-to-b from-white to-blue-50">
            <div className="container mx-auto px-4">
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                    className="flex flex-col gap-16"
                >
                    {/* Section Header */}
                    <div className="text-center max-w-3xl mx-auto">
                        <h2 className="text-3xl md:text-4xl font-bold mb-6">
                            Our <GradientText>Culture</GradientText>
                        </h2>
                        <p className="text-lg text-gray-600">
                            At Qubitware, we've built a workplace that encourages innovation, rewards initiative, 
                            and celebrates diversity. Our culture is the foundation of our success.
                        </p>
                    </div>
                    
                    {/* Culture Values */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        {cultureValues.map((value, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0, y: 30 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                                className="bg-white rounded-xl p-8 shadow-md hover:shadow-xl transition-all duration-300 border border-gray-100 flex flex-col items-center text-center"
                            >
                                <div className="text-4xl mb-4">{value.icon}</div>
                                <h3 className="text-xl font-bold mb-3 text-gray-900">{value.title}</h3>
                                <p className="text-gray-600">{value.description}</p>
                            </motion.div>
                        ))}
                    </div>
                    
                    {/* Team Photos/Culture showcase */}
                    <div className="flex flex-col md:flex-row gap-8 items-center">
                        <motion.div 
                            initial={{ opacity: 0, x: -30 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5 }}
                            className="md:w-1/2"
                        >
                            <div className="relative rounded-xl overflow-hidden shadow-lg">
                                <div className="aspect-w-16 aspect-h-9 overflow-hidden">
                                    <img 
                                        src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80" 
                                        alt="Team collaborating during a hackathon event" 
                                        className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = "https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80";
                                        }}
                                    />
                                </div>
                                <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-end">
                                    <p className="text-white p-6 font-medium">Our team collaborating during a hackathon event</p>
                                </div>
                            </div>
                        </motion.div>
                        
                        <motion.div 
                            initial={{ opacity: 0, x: 30 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            className="md:w-1/2"
                        >
                            <h3 className="text-2xl font-bold mb-4">Work with purpose</h3>
                            <p className="text-gray-600 mb-6">
                                We believe that meaningful work drives passion. Our projects focus on creating solutions that 
                                make a real difference in how people manage and protect their digital identity.
                            </p>
                            <div className="space-y-4">
                                <div className="flex items-start gap-3">
                                    <div className="bg-gradient-to-r from-cblue to-cgreen p-0.5 rounded-full">
                                        <div className="bg-white p-2 rounded-full">
                                            <svg className="w-5 h-5 text-cblue" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <p className="text-gray-700">Regular team building activities and events</p>
                                </div>
                                <div className="flex items-start gap-3">
                                    <div className="bg-gradient-to-r from-cblue to-cgreen p-0.5 rounded-full">
                                        <div className="bg-white p-2 rounded-full">
                                            <svg className="w-5 h-5 text-cblue" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <p className="text-gray-700">Flexible work arrangements that respect work-life balance</p>
                                </div>
                                <div className="flex items-start gap-3">
                                    <div className="bg-gradient-to-r from-cblue to-cgreen p-0.5 rounded-full">
                                        <div className="bg-white p-2 rounded-full">
                                            <svg className="w-5 h-5 text-cblue" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <p className="text-gray-700">Budget for continuous learning and professional development</p>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                    
                    {/* Employee Testimonial */}
                    <div className="flex flex-col gap-8 items-center">
                    {employeeTestimonial.map((testimonial, index) => (
                        <motion.div 
                            key={index}
                            initial={{ opacity: 0, y: 30 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5 }}
                        className="bg-gradient-to-r from-blue-700 to-indigo-800 rounded-2xl p-8 md:p-12 shadow-xl"
                    >
                        <div className="flex flex-col md:flex-row items-center gap-8">
                            <div className="min-w-20 min-h-20 rounded-full bg-white/20 flex items-center justify-center">
                                <MdPerson className="text-white text-3xl" />
                            </div>
                            <div className="text-white text-center md:text-left">
                                <p className="text-xl italic mb-4">
                                    {testimonial.testimonial}
                                </p>
                                <p className="font-semibold">{testimonial.name}</p>
                                <p className="text-blue-200">{testimonial.designation}</p>
                            </div>
                        </div>
                    </motion.div>
                    ))}
                    
                   </div>
                </motion.div>
            </div>
        </section>
    );
}

function AllJobsView({ jobCategoriesList, defaultCategory, jobListings }) {
    const [selectedCategory, setSelectedCategory] = useState(defaultCategory)
    const [jobsListOnView, setJobsListOnView] = useState(jobListings)

    const categoryClickHandler = (category) => {
        setSelectedCategory(category);
        if(category !== defaultCategory) {
            setJobsListOnView(jobListings.filter((job) => job.department_name === category))
        }
        else {
            setJobsListOnView(jobListings)
        }
    }

    return (
        <section id="job-listings" className="py-20 bg-white">
            <div className="container mx-auto px-4">
                <motion.div 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    className="flex flex-col gap-12"
                >
                    {/* Section Title */}
                    <div className="text-center">
                        <h2 className="text-3xl md:text-4xl font-bold mb-4">
                            <GradientText>Current Openings</GradientText>
                        </h2>
                        <p className="text-gray-600 max-w-2xl mx-auto">
                            Browse our available positions and find where your skills and passion align with our mission.
                        </p>
                    </div>

                    {/* Job Categories */}
                    <div className="flex flex-wrap justify-center gap-4">
                        {jobCategoriesList.map((category, index) => (
                            <motion.button
                                key={index}
                                onClick={() => categoryClickHandler(category)}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className={`px-6 py-2 rounded-full text-base font-semibold transition-all duration-300
                                    ${selectedCategory === category
                                        ? 'bg-gradient-to-r from-cblue to-cgreen text-white shadow-lg shadow-blue-500/20'
                                        : 'bg-white text-gray-700 hover:bg-gray-50 border-2 border-gray-200 hover:border-cblue'}`}
                            >
                                {category}
                            </motion.button>
                        ))}
                    </div>

                    {/* Job Postings */}
                    <div className="flex flex-col gap-8 mt-6">
                        {jobsListOnView.length === 0 ? (
                            <motion.div 
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                className="text-center py-16 bg-blue-50/50 rounded-2xl"
                            >
                                <div className="max-w-md mx-auto">
                                    <img 
                                        src="/images/no-openings.svg" 
                                        alt="No current openings" 
                                        className="w-48 h-48 mx-auto mb-6 opacity-70"
                                        onError={(e) => e.target.style.display = 'none'}
                                    />
                                    <h3 className="text-2xl font-semibold text-gray-700 mb-3">
                                        No openings available
                                    </h3>
                                    <p className="text-gray-500">
                                        We don't have any positions open in this category right now, but please check back soon or explore other departments.
                                    </p>
                                </div>
                            </motion.div>
                        ) : (
                            jobsListOnView.map((job, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.4, delay: index * 0.1 }}
                                >
                                    <JobPostCard jobPost={job} />
                                    {index < jobsListOnView.length - 1 && (
                                        <hr className="my-8 border-t-2 border-gray-100" />
                                    )}
                                </motion.div>
                            ))
                        )}
                    </div>
                </motion.div>
            </div>
        </section>
    );
}

function JobPostCard({ jobPost }) {
    const { title, short_description, job_id } = jobPost;
    const tags = [
        jobPost.job_location,
        { FULL_TIME: 'Full-time', PART_TIME: 'Part-Time', NEED_BASIS: 'Need-Basis' }[jobPost.employment_type],
        jobPost.min_experience > 0 ? `${jobPost.min_experience}+ years` : null
    ].filter(Boolean);
    
    const navigate = useNavigate();
    
    return (
        <motion.div 
            whileHover={{ scale: 1.01, y: -2 }}
            className="group cursor-pointer bg-white rounded-xl p-6 md:p-8 shadow-sm hover:shadow-xl transition-all duration-300 border border-gray-100"
            onClick={() => navigate('/careers/jobs/' + job_id)}
        >
            <div className="flex flex-col md:flex-row justify-between gap-6">
                <div className="flex flex-col gap-4 flex-grow">
                    <h1 className="text-2xl md:text-3xl font-bold text-gray-900 group-hover:text-cblue transition-colors">
                        {title}
                    </h1>
                    <p className="text-lg text-gray-600 line-clamp-3">
                        {short_description}
                    </p>
                    <div className="flex flex-wrap gap-3 mt-2">
                        {tags.map((tag, index) => (
                            <span
                                key={index}
                                className="px-4 py-1.5 rounded-full text-sm font-medium bg-gradient-to-r from-blue-50 to-indigo-50 text-blue-700 border border-blue-100"
                            >
                                {tag}
                            </span>
                        ))}
                    </div>
                </div>
                <motion.div 
                    whileHover={{ x: 5 }}
                    className="flex items-center self-end md:self-center"
                >
                    <div className="flex items-center gap-2 px-5 py-2 rounded-full bg-blue-50 text-cblue font-semibold group-hover:bg-cblue group-hover:text-white transition-all duration-300">
                        <span>View Details</span>
                        <MdArrowForward className="text-xl transform -rotate-45" />
                    </div>
                </motion.div>
            </div>
        </motion.div>
    );
}

export default Careers;