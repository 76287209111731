import React from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const MobileInput = React.forwardRef(
    (
        {
            className = "",
            placeholder = " ",
            ...inputProps
        },
        ref
    ) => {
        return (
            <PhoneInput
                className={`${className} `}
                placeholder={placeholder}
                value={inputProps.value}
                onChange={inputProps.onChange}
                name={inputProps.name}
                defaultCountry="IN"
                international
                initialValueFormat="national"
                inputComponent={inputProps.inputComponent || CustomInput}
                ref={ref}
            />
        );
    }
);


const CustomInput = React.forwardRef((props, ref) => {
    return (
        <input {...props} className="focus:outline-none w-full" ref={ref}/>
    )
})

export default MobileInput;