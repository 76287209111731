import React, { useEffect, useState } from 'react';

const Alert = ({ message, type = 'success', onClose }) => {
    const [visible, setVisible] = useState(true);
    const [progress, setProgress] = useState(100);
    const duration = 3000;

    const alertStyles = {
        success: {
            container: 'bg-green-100 text-green-700 border-green-500',
            progress: 'bg-green-500',
        },
        error: {
            container: 'bg-red-100 text-red-700 border-red-500',
            progress: 'bg-red-500',
        },
        warning: {
            container: 'bg-yellow-100 text-yellow-700 border-yellow-500',
            progress: 'bg-yellow-500',
        },
        info: {
            container: 'bg-blue-100 text-blue-700 border-blue-500',
            progress: 'bg-blue-500',
        },
    };

    useEffect(() => {
        const interval = duration / 100;
        const timer = setInterval(() => {
            setProgress((prev) => prev - 1);
        }, interval);

        const timeout = setTimeout(() => {
            setVisible(false);
            if (onClose) onClose();
        }, duration);

        return () => {
            clearInterval(timer);
            clearTimeout(timeout);
        };
    }, [onClose]);

    if (!visible) return null;

    return (
        <div className={`fixed top-24 right-4 p-4 border-l-4 z-20 rounded-md shadow-md ${alertStyles[type].container}`}>
            <span>{message}</span>
            <div className="relative w-full h-1 mt-2 bg-gray-300 rounded">
                <div
                    className={`absolute top-0 left-0 h-full ${alertStyles[type].progress}`}
                    style={{ width: `${progress}%` }}
                ></div>
            </div>
        </div>
    );
};

export default Alert;
