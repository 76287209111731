import { useState } from "react";
import logo_main from "../assets/logo/Main-Logo.png";
import logo_light from "../assets/logo/Logo-for-Blue-BG-1_1_.png"
import { Link } from "react-router-dom";
import { IoCallSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaLocationDot, FaArrowUp, FaXTwitter, FaLinkedin, FaReddit } from "react-icons/fa6";
import { motion } from "framer-motion";

export default function Footer({ varient = ""}) {
    const [emailInput, setEmailInput] = useState("");
    const [subscribeStatus, setSubscribeStatus] = useState(null);
    
    // Social media links
    const socialLinks = [
        { 
            icon: <FaLinkedin className="text-lg" />, 
            url: "https://www.linkedin.com/company/jmr-technologies-in/",
            label: "LinkedIn",
            hoverColor: "hover:bg-[#0077b5]"
        },
        { 
            icon: <FaXTwitter className="text-lg" />, 
            url: "https://x.com/technologi21594",
            label: "Twitter",
            hoverColor: "hover:bg-[#1DA1F2]" 
        }
    ];
    
    const handleSubscribe = (e) => {
        e.preventDefault();
        
        if (!emailInput || !/\S+@\S+\.\S+/.test(emailInput)) {
            setSubscribeStatus("error");
            return;
        }
        
        // Here you would normally send the email to your backend
        console.log("Subscribing email:", emailInput);
        setSubscribeStatus("success");
        setEmailInput("");
        
        // Reset status after 3 seconds
        setTimeout(() => {
            setSubscribeStatus(null);
        }, 3000);
    };
    
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <section
            className={`relative pt-16 pb-8 ${
                varient === "blue"
                    ? "bg-gradient-to-br from-blue-700 to-blue-900 text-white"
                    : varient === "dark"
                    ? "bg-gray-900 text-white"
                    : "bg-gray-50 text-gray-800"
            }`}
        >
            {/* Wave Pattern Top */}
            {/* <div className="absolute top-0 left-0 right-0 h-12 overflow-hidden">
                <svg className="absolute bottom-0 w-full h-16 transform translate-y-1/2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path 
                        fill={varient === "blue" ? "#3182CE" : varient === "dark" ? "#1A202C" : "#F9FAFB"} 
                        fillOpacity="1" 
                        d="M0,192L48,170.7C96,149,192,107,288,112C384,117,480,171,576,197.3C672,224,768,224,864,197.3C960,171,1056,117,1152,117.3C1248,117,1344,171,1392,197.3L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
                    </path>
                </svg>
            </div> */}
            
            {/* Back to top button */}
            <motion.button
                initial={{ opacity: 0.6 }}
                whileHover={{ opacity: 1, y: -5 }}
                whileTap={{ scale: 0.95 }}
                onClick={scrollToTop}
                className={`absolute top-4 right-4 p-3 rounded-full shadow-lg 
                    ${varient === "blue" || varient === "dark" 
                        ? "bg-white text-blue-700" 
                        : "bg-blue-600 text-white"}`}
                aria-label="Back to top"
            >
                <FaArrowUp />
            </motion.button>

            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-6 xl:grid-cols-12">
                    {/* Logo and company info */}
                    <div className="lg:col-span-2 xl:col-span-3 flex flex-col">
                        <div className="mb-6">
                            <img
                                className="h-16 md:h-20"
                                src={varient === "blue" || varient === "dark" ? logo_light : logo_main}
                                alt="QUBITWARE Logo"
                            />
                        </div>
                        <p className={`mb-6 text-sm ${
                            varient === "blue" || varient === "dark" ? "text-gray-300" : "text-gray-600"
                        }`}>
                            Empowering businesses through innovative AI and IT solutions. 
                            We deliver cutting-edge technology services tailored to your unique challenges.
                        </p>
                        
                        {/* Social Media Links */}
                        <div className="flex gap-3 mt-auto">
                            {socialLinks.map((link, index) => (
                                <motion.a
                                    key={index}
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.95 }}
                                    href={link.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label={link.label}
                                    className={`p-2.5 rounded-full ${
                                        varient === "blue" || varient === "dark"
                                            ? "bg-white/10 text-white hover:bg-white/20"
                                            : "bg-gray-200 text-gray-700 hover:text-white " + link.hoverColor
                                    } transition-all duration-300`}
                                >
                                    {link.icon}
                                </motion.a>
                            ))}
                        </div>
                    </div>

                    {/* Main footer links */}
                    <div className="grid grid-cols-2 gap-8 sm:grid-cols-4 md:grid-cols-2 lg:col-span-4 xl:col-span-5">
                        {/* Company links */}
                        <div>
                            <h3 className="mb-4 text-lg font-bold">
                                Company
                            </h3>
                            <ul className="space-y-3">
                                {[
                                    { path: "/services", label: "Services" },
                                    { path: "/partners", label: "Partners" },
                                    { path: "/", label: "About Us" },
                                    { path: "/blogs", label: "Resources" },
                                ].map((link, index) => (
                                    <li key={index}>
                                        <Link
                                            className={`inline-block transition-all duration-300 text-sm hover:translate-x-1 ${
                                                varient === "blue" || varient === "dark"
                                                    ? "text-gray-300 hover:text-white"
                                                    : "text-gray-600 hover:text-blue-600"
                                            }`}
                                            to={link.path}
                                        >
                                            {link.label}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Legal links */}
                        <div>
                            <h3 className="mb-4 text-lg font-bold">
                                Legal
                            </h3>
                            <ul className="space-y-3">
                                {[
                                    { path: "/privacypolicy", label: "Privacy Policy" },
                                    { path: "/terms-of-service", label: "Terms of Service" },
                                ].map((link, index) => (
                                    <li key={index}>
                                        <Link
                                            className={`inline-block transition-all duration-300 text-sm hover:translate-x-1 ${
                                                varient === "blue" || varient === "dark"
                                                    ? "text-gray-300 hover:text-white"
                                                    : "text-gray-600 hover:text-blue-600"
                                            }`}
                                            to={link.path}
                                        >
                                            {link.label}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Quick links */}
                        <div>
                            <h3 className="mb-4 text-lg font-bold">
                                Quick Links
                            </h3>
                            <ul className="space-y-3">
                                {[
                                    { path: "/contact-us#contact-us-section", label: "Contact Us" },
                                    { path: "/careers", label: "Careers" },
                                    { path: "/contact-us#faq-section", label: "FAQs" }
                                ].map((link, index) => (
                                    <li key={index}>
                                        <Link
                                            className={`inline-block transition-all duration-300 text-sm hover:translate-x-1 ${
                                                varient === "blue" || varient === "dark"
                                                    ? "text-gray-300 hover:text-white"
                                                    : "text-gray-600 hover:text-blue-600"
                                            }`}
                                            to={link.path}
                                        >
                                            {link.label}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Contact details */}
                        <div>
                            <h3 className="mb-4 text-lg font-bold">
                                Contact Us
                            </h3>
                            <ul className="space-y-3">
                                {[
                                    {
                                        icon: <IoCallSharp className="size-4 flex-shrink-0" />,
                                        content: "+91 9354407238",
                                        link: "tel:+919354407238"
                                    },
                                    {
                                        icon: <MdEmail className="size-4 flex-shrink-0" />,
                                        content: "helpdesk@qubitware.in",
                                        link: "mailto:helpdesk@qubitware.in"
                                    },
                                    {
                                        icon: <FaLocationDot className="size-4 flex-shrink-0" />,
                                        content: "Jaipur - 302034, Rajasthan, IN",
                                        link: "https://maps.google.com/?q=Bishanawala,+Panchyawala+Jaipur+-+302034,+Rajasthan"
                                    }
                                ].map((item, index) => (
                                    <li key={index}>
                                        <a 
                                            href={item.link}
                                            target={item.icon.type === FaLocationDot ? "_blank" : "_self"}
                                            rel="noopener noreferrer"
                                            className={`flex items-start gap-2 text-sm group ${
                                                varient === "blue" || varient === "dark"
                                                    ? "text-gray-300 hover:text-white"
                                                    : "text-gray-600 hover:text-blue-600"
                                            } transition-colors duration-300`}
                                        >
                                            <span className="mt-0.5 group-hover:scale-110 transition-transform duration-300">
                                                {item.icon}
                                            </span>
                                            <span>{item.content}</span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    {/* Newsletter */}
                    <div className="lg:col-span-6 xl:col-span-4">
                        <div className={`p-6 rounded-xl shadow-lg ${
                            varient === "blue" ? "bg-blue-800" : 
                            varient === "dark" ? "bg-gray-800" : 
                            "bg-white"
                        }`}>
                            <h3 className="text-xl font-bold mb-3">
                                Join Our Newsletter
                            </h3>
                            <p className={`mb-4 text-sm ${
                                varient === "blue" || varient === "dark" ? "text-gray-300" : "text-gray-600"
                            }`}>
                                Stay updated with our latest news, articles and special offers.
                            </p>
                            
                            <form onSubmit={handleSubscribe} className="space-y-3">
                                <div className="relative">
                                    <input
                                        type="email"
                                        value={emailInput}
                                        onChange={(e) => setEmailInput(e.target.value)}
                                        className={`w-full rounded-lg p-3 pr-24 text-gray-800 focus:outline-none focus:ring-2 ${
                                            subscribeStatus === "error" ? "border-red-500 focus:ring-red-500" : "focus:ring-blue-500"
                                        } ${
                                            varient === "blue" || varient === "dark" ? "bg-gray-100" : "bg-gray-100"
                                        }`}
                                        placeholder="Your email address"
                                    />
                                    <motion.button
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                        type="submit"
                                        className={`absolute right-1.5 top-1.5 rounded-md px-4 py-1.5 font-medium text-white ${
                                            varient === "blue" ? "bg-gradient-to-r from-blue-500 to-indigo-600" : 
                                            varient === "dark" ? "bg-gradient-to-r from-blue-600 to-indigo-700" : 
                                            "bg-gradient-to-r from-blue-600 to-indigo-600"
                                        } transition-all duration-300 hover:shadow-md`}
                                    >
                                        Subscribe
                                    </motion.button>
                                </div>
                                
                                {/* Subscription status message */}
                                {subscribeStatus === "success" && (
                                    <p className="text-green-500 text-sm">Thank you for subscribing!</p>
                                )}
                                {subscribeStatus === "error" && (
                                    <p className="text-red-500 text-sm">Please enter a valid email address.</p>
                                )}
                                
                                <p className={`text-xs ${
                                    varient === "blue" || varient === "dark" ? "text-gray-400" : "text-gray-500"
                                }`}>
                                    We respect your privacy. Unsubscribe at any time.
                                </p>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Bottom footer with copyright */}
                <div className="mt-12 pt-6 border-t border-opacity-20 flex flex-col sm:flex-row justify-between items-center gap-4
                    border-gray-300">
                    <p className={`text-sm ${
                        varient === "blue" || varient === "dark" ? "text-gray-400" : "text-gray-600"
                    }`}>
                        © {new Date().getFullYear()} | QUBITWARE | All rights reserved
                    </p>
                    
                    <p className={`text-xs ${
                        varient === "blue" || varient === "dark" ? "text-gray-400" : "text-gray-500"
                    }`}>
                        Designed and developed with ❤️ in India
                    </p>
                </div>
            </div>
        </section>
    );
}
