import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Outlet, useLoaderData, useNavigate, Navigate, useSearchParams, Routes, Route } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { motion } from "framer-motion";
import { FaSearch, FaTimes, FaFilter } from "react-icons/fa";
import BlogCard from "../components/BlogCard.component";
import BlogCardBig from "../components/BlogCardBig.component";
import Tag from "../components/Tag.component";
import Footer from "../components/Footer.component";
import GradientText from "../components/GradientText.component";
import BlogPost, { getBlogLoader } from "./BlogPost.page";
import "react-loading-skeleton/dist/skeleton.css";
import TagsFilter from "../components/TagsFilter.component";
import Timestamp from "react-timestamp";
import { FaMoon, FaSun } from "react-icons/fa6";

const fetchAllBlogsLoader = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blog/all/`);
        return response.data;
    } catch (error) {
        console.error(error);
        return { error: error.message || "Failed to load blogs", data: [] };
    }
};

const fetchAllTagsLoader = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blog/tags/`);
        return response.data;
    } catch (error) {
        console.error(error);
        return { error: error.message || "Failed to load tags", data: [] };
    }
};

export const blogsPageDataLoader = async () => {
    const blogsResult = await fetchAllBlogsLoader();
    const tagsResult = await fetchAllTagsLoader();
    
    return {
        blogs: blogsResult.error ? [] : blogsResult,
        blogTagsList: tagsResult.error ? [] : tagsResult,
        errors: {
            blogs: blogsResult.error || null,
            tags: tagsResult.error || null
        }
    };
};

export const blogs_routes = [
    {
        path: '',
        element: <AllBlogs />,
        loader: blogsPageDataLoader
    },
    {
        path: ':blog_id/:title',
        element: <BlogPost />,
        loader: getBlogLoader
    },
    {
        path: '*',
        element: <Navigate to="/not-found" />
    }
];

export default function Blogs() {
    return (
        <Outlet/>
    );
}

function AllBlogs() {
    const { blogs, blogTagsList, errors } = useLoaderData();
    const [searchParams, setSearchParams] = useSearchParams();
    const tagFromUrl = searchParams.get('tag');
    const searchFromUrl = searchParams.get('search');
    
    const [selectedTag, setSelectedTag] = useState(tagFromUrl || null);
    const [searchTerm, setSearchTerm] = useState(searchFromUrl || '');
    const [showMobileFilters, setShowMobileFilters] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [filteredBlogs, setFilteredBlogs] = useState(blogs);
    const [view, setView] = useState('grid'); // 'grid' or 'list'
    const [isThemeLight, setIsThemeLight] = useState(true);
    console.log("AllBlogs", blogs);
    
    const navigate = useNavigate();

    // Update the filtered blogs when search term or selected tag changes
    useEffect(() => {
        setIsLoading(true);
        
        // Simulate a brief loading state for better UX
        const timer = setTimeout(() => {
            let filtered = [...blogs];
            
            // Filter by tag
            if (selectedTag) {
                filtered = filtered.filter(blog => 
                    blog.tags && blog.tags.includes(selectedTag)
                );
            }
            
            // Filter by search term
            if (searchTerm) {
                const searchLower = searchTerm.toLowerCase();
                filtered = filtered.filter(blog => 
                    blog.title.toLowerCase().includes(searchLower) || 
                    blog.short_description.toLowerCase().includes(searchLower) ||
                    (blog.content && blog.content.toLowerCase().includes(searchLower))
                );
            }
            
            setFilteredBlogs(filtered);
            setIsLoading(false);
        }, 300); // Brief timeout for loading state
        
        return () => clearTimeout(timer);
    }, [blogs, selectedTag, searchTerm]);

    // Update page title and scroll position
    useEffect(() => {
        document.title = selectedTag 
            ? `${selectedTag} Resources | QUBITWARE` 
            : "Resources | QUBITWARE";
        window.scrollTo(0, 0);
    }, [selectedTag]);
    
    // Update URL when tag or search changes
    useEffect(() => {
        const params = new URLSearchParams();
        if (selectedTag) params.set('tag', selectedTag);
        if (searchTerm) params.set('search', searchTerm);
        
        setSearchParams(params, { replace: true });
    }, [selectedTag, searchTerm, setSearchParams]);

    const tagClickHandler = (tag, isSelected) => {
        console.log("tagClickHandler", tag, isSelected);
        if (isSelected) {
            setSelectedTag(null);
        } else {
            setSelectedTag(tag?.name);
        }
    };
    
    const handleSearchSubmit = (e) => {
        e.preventDefault();
    };
    
    const clearSearch = () => {
        setSearchTerm('');
    };
    
    const clearFilters = () => {
        setSelectedTag(null);
        setSearchTerm('');
    };
    
    const toggleMobileFilters = () => {
        setShowMobileFilters(prev => !prev);
    };
    
    // Toggle theme
    const toggleTheme = () => {
        setIsThemeLight(!isThemeLight);
    };

    // Error states
    if (errors && (errors.blogs || errors.tags)) {
        return (
            <div className="min-h-[60vh] flex flex-col items-center justify-center p-6">
                <div className="p-6 bg-red-50 rounded-lg max-w-xl w-full text-center">
                    <h1 className="text-2xl font-bold text-red-600 mb-4">
                        Something went wrong
                    </h1>
                    <p className="text-gray-700 mb-6">
                        {errors.blogs || errors.tags}
                    </p>
                    <button 
                        onClick={() => window.location.reload()}
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                    >
                        Try Again
                    </button>
                </div>
            </div>
        );
    }

    // Empty state (no blogs or empty search results)
    if (filteredBlogs.length === 0) {
        return (
            <div className={`min-h-[60vh] ${isThemeLight ? 'bg-white' : 'bg-gray-900 text-white'} transition-all duration-300`}>
                <div className="container mx-auto px-4 py-12 flex flex-col items-center justify-center text-center">
                    <div className={`p-8 rounded-lg ${isThemeLight ? 'bg-gray-50' : 'bg-gray-800'} max-w-xl w-full`}>
                        <svg 
                            className={`w-16 h-16 mx-auto mb-6 ${isThemeLight ? 'text-gray-400' : 'text-gray-500'}`} 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24" 
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path>
                        </svg>
                        
                        <h2 className="text-2xl font-bold mb-4">No resources found</h2>
                        <p className={`mb-6 ${isThemeLight ? 'text-gray-600' : 'text-gray-300'}`}>
                            {selectedTag || searchTerm 
                                ? "We couldn't find any resources matching your current filters."
                                : "We're working on adding resources. Check back soon!"
                            }
                        </p>
                        
                        {(selectedTag || searchTerm) && (
                            <button
                                onClick={clearFilters}
                                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                            >
                                Clear Filters
                            </button>
                        )}
                    </div>
                </div>
                <Footer varient={isThemeLight ? "blue" : "dark"} />
            </div>
        );
    }

    // Define animation variants
    const fadeIn = {
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0, transition: { duration: 0.5 } }
    };

    return (
        <div className={`flex flex-col ${isThemeLight ? 'bg-white' : 'bg-gray-900 text-white'} transition-all duration-300`}>
            {/* Theme toggle */}
            <div className="fixed top-24 right-6 z-50">
                <button 
                    onClick={toggleTheme}
                    className={`p-3 rounded-full shadow-lg ${isThemeLight ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'} transition-all duration-300`}
                >
                     {isThemeLight ? <FaMoon /> : <FaSun />}
                </button>
            </div>
            
            {/* Hero section */}
            <section className={`w-full py-20 ${isThemeLight ? 'bg-gradient-to-r from-blue-50 to-indigo-50' : 'bg-gradient-to-r from-gray-900 to-blue-900'}`}>
                <div className="container mx-auto px-4 text-center">
                    <motion.div 
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                    >
                        <h1 className={`text-5xl md:text-6xl font-bold mb-6 ${!isThemeLight && 'text-white'}`}>
                            Our <GradientText className="inline">Resources</GradientText>
                        </h1>
                        <p className={`text-xl max-w-2xl mx-auto mb-12 ${isThemeLight ? 'text-gray-600' : 'text-gray-300'}`}>
                            Explore the latest insights, tutorials, and updates on AI technology, 
                            software development, and IT service solutions.
                        </p>
                        
                        <SearchBar 
                            searchTerm={searchTerm} 
                            setSearchTerm={setSearchTerm} 
                            handleSearchSubmit={handleSearchSubmit}
                            clearSearch={clearSearch}
                            isThemeLight={isThemeLight}
                            className="max-w-2xl mx-auto"
                        />
                    </motion.div>
                </div>
            </section>
            
            <div className="container mx-auto py-12 px-4">
                {/* Top filters and view options */}
                <div className="flex flex-wrap justify-between items-center mb-8">
                    <div className="flex items-center gap-2 mb-4 md:mb-0">
                        <span className={`${isThemeLight ? 'text-gray-600' : 'text-gray-300'}`}>View:</span>
                        <button 
                            onClick={() => setView('grid')}
                            className={`p-2 rounded ${view === 'grid' 
                                ? `${isThemeLight ? 'bg-blue-100 text-blue-600' : 'bg-blue-900 text-blue-300'}` 
                                : `${isThemeLight ? 'bg-gray-100 text-gray-600' : 'bg-gray-800 text-gray-400'}`
                            } transition-colors`}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                            </svg>
                        </button>
                        <button 
                            onClick={() => setView('list')}
                            className={`p-2 rounded ${view === 'list' 
                                ? `${isThemeLight ? 'bg-blue-100 text-blue-600' : 'bg-blue-900 text-blue-300'}` 
                                : `${isThemeLight ? 'bg-gray-100 text-gray-600' : 'bg-gray-800 text-gray-400'}`
                            } transition-colors`}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                    </div>
                    
                    {/* Mobile filter button */}
                    <div className="flex gap-2 md:hidden">
                        <button 
                            onClick={toggleMobileFilters}
                            className={`flex items-center gap-2 px-4 py-2 ${isThemeLight ? 'bg-blue-600' : 'bg-blue-700'} text-white rounded-md`}
                        >
                            <FaFilter /> Filters {selectedTag && '(1)'}
                        </button>
                        
                        {selectedTag && (
                            <button
                                onClick={clearFilters}
                                className={`px-4 py-2 ${isThemeLight ? 'bg-gray-200 text-gray-800' : 'bg-gray-700 text-white'} rounded-md`}
                            >
                                Clear
                            </button>
                        )}
                    </div>
                </div>
                
                <div className="flex flex-col md:flex-row gap-8">
                    {/* Sidebar with filters */}
                    <aside className={`md:w-1/4 lg:w-1/5 ${showMobileFilters ? 'block' : 'hidden'} md:block`}>
                        <div className={`sticky top-24 p-6 rounded-xl ${isThemeLight ? 'bg-gray-50' : 'bg-gray-800'}`}>
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-bold">Categories</h3>
                                {selectedTag && (
                                    <button
                                        onClick={clearFilters}
                                        className="text-sm text-blue-600 hover:underline"
                                    >
                                        Clear
                                    </button>
                                )}
                            </div>
                            
                            <TagsFilter 
                                tags={blogTagsList} 
                                selectedTag={selectedTag} 
                                onTagSelect={tagClickHandler}
                                isThemeLight={isThemeLight}
                            />
                            
                            {/* Email subscription */}
                            <div className={`mt-10 p-4 rounded-lg ${isThemeLight ? 'bg-blue-50' : 'bg-blue-900/30'}`}>
                                <h4 className="font-bold mb-2">Stay Updated</h4>
                                <p className={`text-sm mb-4 ${isThemeLight ? 'text-gray-600' : 'text-gray-300'}`}>
                                    Get the latest resources in your inbox.
                                </p>
                                <div className="space-y-2">
                                    <input 
                                        type="email" 
                                        placeholder="Your email" 
                                        className={`w-full p-2 text-sm rounded ${isThemeLight ? 'bg-white' : 'bg-gray-700'} border ${isThemeLight ? 'border-gray-200' : 'border-gray-600'}`}
                                    />
                                    <button className="w-full p-2 text-sm bg-blue-600 hover:bg-blue-700 text-white rounded transition-colors">
                                        Subscribe
                                    </button>
                                </div>
                            </div>
                        </div>
                    </aside>
                    
                    {/* Main content */}
                    <main className="md:w-3/4 lg:w-4/5">
                        {/* Active filters */}
                        {selectedTag && (
                            <div className="mb-6 flex items-center gap-2">
                                <span className={`${isThemeLight ? 'text-gray-600' : 'text-gray-300'}`}>Active filter:</span>
                                <div className={`px-3 py-1 rounded-full ${isThemeLight ? 'bg-blue-100 text-blue-700' : 'bg-blue-900 text-blue-100'} flex items-center gap-1`}>
                                    {selectedTag}
                                    <button 
                                        onClick={() => setSelectedTag(null)}
                                        className={`ml-1 rounded-full p-0.5 ${isThemeLight ? 'hover:bg-blue-200' : 'hover:bg-blue-800'}`}
                                    >
                                        <FaTimes size={10} />
                                    </button>
                                </div>
                            </div>
                        )}
                        
                        {/* Featured Blog - only show if not filtering */}
                        {!selectedTag && !searchTerm && filteredBlogs.length > 0 && (
                            <div className="mb-12">
                                <FeaturedBlog blog={filteredBlogs[0]} isThemeLight={isThemeLight} />
                            </div>
                        )}
                        
                        {/* Loader */}
                        {isLoading ? (
                            <div className="flex justify-center items-center py-20">
                                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                            </div>
                        ) : (
                            <>
                                {/* Blog list */}
                                <h2 className={`text-2xl font-bold mb-6 ${!isThemeLight && 'text-white'}`}>
                                    {selectedTag 
                                        ? `${selectedTag} Resources` 
                                        : searchTerm 
                                            ? `Search Results for "${searchTerm}"` 
                                            : "All Resources"
                                    }
                                    <span className="text-gray-500 text-lg font-normal ml-2">
                                        ({filteredBlogs?.length})
                                    </span>
                                </h2>
                                
                                {view === 'grid' ? (
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                        {filteredBlogs.slice(selectedTag || searchTerm ? 0 : 1).map((blog, index) => (
                                            <motion.div
                                                key={blog?.id}
                                                variants={fadeIn}
                                                initial="initial"
                                                animate="animate"
                                                transition={{ delay: index * 0.1 }}
                                            >
                                                <BlogCardBig blogData={blog || {}} />
                                            </motion.div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="space-y-6">
                                        {filteredBlogs.slice(selectedTag || searchTerm ? 0 : 1).map((blog, index) => (
                                            <motion.div
                                                key={blog?.id}
                                                variants={fadeIn}
                                                initial="initial"
                                                animate="animate"
                                                transition={{ delay: index * 0.1 }}
                                            >
                                                <BlogCard details={blog || {}} />
                                            </motion.div>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                        
                        {/* Newsletter CTA */}
                        <div className={`mt-16 p-8 rounded-xl ${isThemeLight ? 'bg-gradient-to-r from-blue-50 to-indigo-50' : 'bg-gradient-to-r from-blue-900/30 to-indigo-900/30'}`}>
                            <div className="flex flex-col md:flex-row items-center gap-6">
                                <div className="md:w-2/3">
                                    <h3 className="text-2xl font-bold mb-2">Stay ahead with our newsletter</h3>
                                    <p className={`${isThemeLight ? 'text-gray-600' : 'text-gray-300'}`}>
                                        Get the latest resources, tutorials, and updates on AI technology
                                        and IT services delivered straight to your inbox.
                                    </p>
                                </div>
                                <div className="md:w-1/3 w-full">
                                    <div className="flex flex-col sm:flex-row gap-2">
                                        <input 
                                            type="email" 
                                            placeholder="Your email address" 
                                            className={`flex-1 py-3 px-4 rounded-lg ${isThemeLight ? 'bg-white' : 'bg-gray-800'} border ${isThemeLight ? 'border-gray-200' : 'border-gray-700'} focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                        />
                                        <button className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-6 rounded-lg transition-colors">
                                            Subscribe
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            
            <Footer varient={isThemeLight ? "blue" : "dark"} />
        </div>
    );
}
function SearchBar({ searchTerm, setSearchTerm, handleSearchSubmit, clearSearch, isThemeLight, className }) {
    return (
        <form onSubmit={handleSearchSubmit} className={`relative ${className}`}>
            <div className={`flex items-center py-1 px-4 rounded-full ${isThemeLight ? 'bg-white' : 'bg-gray-800'} shadow-md`}>
                <FaSearch className={`${isThemeLight ? 'text-gray-500' : 'text-gray-400'}`} />
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search for resources..."
                    className={`w-full py-3 px-3 ${isThemeLight ? 'bg-white text-gray-800' : 'bg-gray-800 text-white'} focus:outline-none`}
                />
                {searchTerm && (
                    <button 
                        type="button" 
                        onClick={clearSearch} 
                        className={`p-1 rounded-full ${isThemeLight ? 'text-gray-500 hover:bg-gray-100' : 'text-gray-400 hover:bg-gray-700'}`}
                    >
                        <FaTimes />
                    </button>
                )}
            </div>
        </form>
    );
}

function FeaturedBlog({ blog, isThemeLight }) {
    const navigate = useNavigate();
    
    return (
        <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className={`relative overflow-hidden rounded-2xl shadow-lg cursor-pointer group ${isThemeLight ? 'bg-white' : 'bg-gray-800'}`}
            onClick={() => navigate(`/blogs/${blog.id}/${blog.title}`)}
        >
            <div className="relative h-96 overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent z-10"></div>
                <img 
                    src={blog.banner} 
                    alt={blog.title} 
                    className="w-full h-full object-cover object-center transform group-hover:scale-105 transition-transform duration-700"
                />
                <div className="absolute bottom-0 left-0 right-0 p-6 z-20 text-white">
                    <span className="inline-block px-3 py-1 bg-blue-600 text-white text-sm font-medium rounded-full mb-3">
                        Featured
                    </span>
                    <h2 className="text-3xl font-bold mb-3 group-hover:text-blue-300 transition-colors duration-300">
                        {blog.title}
                    </h2>
                    <p className="text-gray-200 mb-4 line-clamp-2">
                        {blog.short_description}
                    </p>
                    <div className="flex items-center text-sm">
                        <span className="mr-3">{blog.read_time} min read</span>
                        <span>•</span>
                        <span className="ml-3 capitalize">
                            <Timestamp relative date={blog.created_at} />
                        </span>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

