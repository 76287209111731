const GradientBall = () => {
    return (
        <div className="absolute inset-0 overflow-visible">
            {/* Gradient Ball */}
            <div
                className="absolute w-60 h-60 bg-gradient-to-br from-cblue to-cgreen rounded-full blur-3xl"
                style={{
                    animation: 'float 6s ease-in-out infinite',
                    top: '30%',
                    right: '20%',
                }}
            ></div>
            <style>
                {`
          @keyframes float {
            0%, 100% {
              transform: translateY(0);
            }
            50% {
              transform: translateX(-100px);
            }
          }
        `}
            </style>
        </div>
    );
};

export default GradientBall;