/* global grecaptcha */
import { useState, useEffect } from "react";

/**
 * Custom hook for reCAPTCHA
 * @param {string} siteKey - reCAPTCHA site key
 * @returns {object} - Object containing executeReCaptcha function
 */
export const useReCaptcha = (siteKey) => {
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        // Initialize reCAPTCHA when the component mounts
        if (window.grecaptcha) {
            grecaptcha.enterprise.ready(() => {
                setIsReady(true);
            });
        }
    }, []);

    const executeReCaptcha = async (action) => {
        if (!isReady) {
            throw new Error("reCAPTCHA not ready");
        }
        return await grecaptcha.enterprise.execute(siteKey, { action });
    };

    return { executeReCaptcha };
};
