import logo_main from "../assets/logo/Main-Logo.png";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { FaReddit, FaXTwitter, FaLinkedin, FaChevronDown } from "react-icons/fa6";
import { motion, AnimatePresence } from "framer-motion";
import { MdAdminPanelSettings } from "react-icons/md";
export default function Navbar() {
    const [activeTab, setActiveTab] = useState(null);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const location = useLocation();
    const navRef = useRef();
    let prevScrollpos = window.scrollY;

    const toggleMenu = () => {
        setIsNavOpen(!isNavOpen);
    };

    const handlePageReload = () => {
        const currentPath = location.pathname.split("/")[1];
        for (let i = 0; i < tabData.length; i++) {
            if (tabData[i].link.toLowerCase() === "/" + currentPath) {
                setActiveTab(tabData[i].label);
                break;
            }
        }
    };

    const tabData = [
        { label: "Home", link: "/" },
        { 
            label: "Services", 
            link: "/services",
        },
        { label: "Partners", link: "/partners" },
        { label: "Resources", link: "/blogs" },
        { label: "Careers", link: "/careers" },
        // { label: "Contact Us", link: "/contact-us" },
    ];

    const socialLinks = [
        { 
            icon: <FaLinkedin size={20} />, 
            url: "https://www.linkedin.com/company/jmr-technologies-in/",
            label: "LinkedIn"
        },
        { 
            icon: <FaXTwitter size={20} />, 
            url: "https://x.com/technologi21594",
            label: "Twitter" 
        }
    ];

    const navScrollHandler = function () {
        const currentScrollPos = window.scrollY;
        
        // Set scrolled state for styling
        setScrolled(currentScrollPos > 20);

        if (prevScrollpos > currentScrollPos) {
            navRef.current.style.top = "0";
            if (currentScrollPos !== 0) {
                navRef.current.style["boxShadow"] =
                    "0 4px 20px -5px rgba(0,0,0,0.1)";
            } else {
                navRef.current.style["boxShadow"] = "";
            }
        } else {
            navRef.current.style.top = "-80px";
            navRef.current.style["boxShadow"] = "";
        }
        prevScrollpos = currentScrollPos;
    };

    const onClickHandler = (tab_label) => {
        setActiveTab(tab_label);
        window.scrollTo(0, 0);
    };

    // Toggle dropdown
    const toggleDropdown = (label) => {
        if (dropdownOpen === label) {
            setDropdownOpen(null);
        } else {
            setDropdownOpen(label);
        }
    };

    // Close menu when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isNavOpen && !event.target.closest('.nav-container')) {
                setIsNavOpen(false);
            }
            
            if (dropdownOpen && !event.target.closest('.dropdown-trigger') && !event.target.closest('.dropdown-menu')) {
                setDropdownOpen(null);
            }
        };
        
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isNavOpen, dropdownOpen]);

    useEffect(() => {
        window.addEventListener("scroll", navScrollHandler);
        handlePageReload();
        setIsNavOpen(false);
        setDropdownOpen(null);
        return () => {
            window.removeEventListener("scroll", navScrollHandler);
        };
    }, [location.pathname]);

    // Mobile menu animation variants
    const mobileMenuVariants = {
        closed: {
            opacity: 0,
            height: 0,
            transition: {
                duration: 0.3,
                ease: "easeInOut"
            }
        },
        open: {
            opacity: 1,
            height: "auto",
            transition: {
                duration: 0.4,
                ease: "easeOut",
                staggerChildren: 0.07,
                delayChildren: 0.1
            }
        }
    };

    const mobileMenuItemVariants = {
        closed: { opacity: 0, y: -10 },
        open: { opacity: 1, y: 0 }
    };

    // Dropdown menu variants
    const dropdownVariants = {
        closed: {
            opacity: 0,
            y: -10,
            height: 0,
            pointerEvents: "none",
            transition: {
                duration: 0.2,
                ease: "easeInOut"
            }
        },
        open: {
            opacity: 1,
            y: 0,
            height: "auto",
            pointerEvents: "auto",
            transition: {
                duration: 0.3,
                ease: "easeOut"
            }
        }
    };

    return (
        <section
            ref={navRef}
            className={`h-20 z-50 sticky bg-white flex items-center duration-500 ease-out
                ${scrolled ? 'backdrop-blur-sm bg-white/90 border-b border-gray-100' : ''}
            `}
        >
            <div className="container mx-auto grid grid-cols-2 nav-container
                lg:grid-cols-3 
                xl:grid-cols-5
            ">
                {/* Logo */}
                <Link 
                    className="flex items-center max-h-16 py-1 lg:col-span-1 transition-transform duration-300 hover:scale-105" 
                    to="/"
                    onClick={() => onClickHandler("Home")}
                >
                    <img
                        className="h-full"
                        src={logo_main}
                        alt="QUBITWARE Logo"
                    />
                </Link>
                
                {/* Hamburger Menu */}
                <div className="flex justify-end items-center z-50
                    lg:hidden
                ">
                    <button
                        className="flex flex-col justify-around size-12 cursor-pointer p-3 rounded-full hover:bg-slate-100"
                        onClick={toggleMenu}
                        aria-label={isNavOpen ? "Close menu" : "Open menu"}
                        aria-expanded={isNavOpen}
                    >
                        <motion.span
                            initial={{ rotate: 0 }}
                            animate={isNavOpen ? { rotate: 45, y: 6 } : { rotate: 0, y: 0 }}
                            transition={{ duration: 0.3 }}
                            className="w-full h-[2px] bg-blue-600 rounded-full"
                        ></motion.span>
                        <motion.span
                            initial={{ opacity: 1 }}
                            animate={isNavOpen ? { opacity: 0 } : { opacity: 1 }}
                            transition={{ duration: 0.3 }}
                            className="w-full h-[2px] bg-blue-600 rounded-full"
                        ></motion.span>
                        <motion.span
                            initial={{ rotate: 0 }}
                            animate={isNavOpen ? { rotate: -45, y: -6 } : { rotate: 0, y: 0 }}
                            transition={{ duration: 0.3 }}
                            className="w-full h-[2px] bg-blue-600 rounded-full"
                        ></motion.span>
                    </button>
                </div>

                {/* Desktop Nav Links */}
                <div className="hidden gap-x-1 items-center text-base lg:flex lg:justify-end lg:col-span-2 xl:col-span-3">
                    {tabData.map((tab, index) => (
                        <div key={index} className="relative">
                            {tab.dropdown ? (
                                <div className="dropdown-trigger">
                                    <button
                                        onClick={() => toggleDropdown(tab.label)}
                                        className={`flex items-center gap-1 px-4 py-2 rounded-full font-medium transition-all duration-300 
                                            ${activeTab === tab.label 
                                                ? 'text-blue-600 bg-blue-50' 
                                                : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50'}`
                                        }
                                    >
                                        {tab.label}
                                        <motion.div
                                            animate={{ rotate: dropdownOpen === tab.label ? 180 : 0 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <FaChevronDown className="text-xs" />
                                        </motion.div>
                                    </button>
                                    
                                    <AnimatePresence>
                                        {dropdownOpen === tab.label && (
                                            <motion.div
                                                initial="closed"
                                                animate="open"
                                                exit="closed"
                                                variants={dropdownVariants}
                                                className="dropdown-menu absolute top-full left-0 mt-1 min-w-[200px] p-2 rounded-xl shadow-lg bg-white border border-gray-100"
                                            >
                                                {tab.dropdown.map((item, idx) => (
                                                    <Link
                                                        key={idx}
                                                        to={item.link}
                                                        className="block px-4 py-2 text-gray-700 hover:text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
                                                        onClick={() => {
                                                            onClickHandler(tab.label);
                                                            setDropdownOpen(null);
                                                        }}
                                                    >
                                                        {item.label}
                                                    </Link>
                                                ))}
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                            ) : (
                                <Link
                                    className={`px-4 py-2 rounded-full font-medium transition-all duration-300 
                                        ${activeTab === tab.label 
                                            ? 'text-blue-600 bg-blue-50' 
                                            : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50'}`
                                    }
                                    to={tab.link}
                                    onClick={() => onClickHandler(tab.label)}
                                >
                                    {tab.label}
                                </Link>
                            )}
                        </div>
                    ))}
                    <Link 
                        to="/contact-us" 
                        className="ml-3 bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 text-white px-5 py-2 rounded-full font-medium transition-all shadow-md hover:shadow-lg"
                    >
                        Get Started
                    </Link>
                </div>

                {/* Social Media Icons */}
                <div className="hidden items-center justify-end gap-x-4 xl:flex">
                    {socialLinks.map((link, index) => (
                        <a
                            key={index}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={link.url}
                            aria-label={link.label}
                            className="text-gray-700 hover:text-blue-600 transition-colors duration-300 hover:scale-110"
                        >
                            {link.icon}
                        </a>
                    ))}
                <Link 
                        to="https://api.qubitware.in/admin" 
                        className="ml-3 text-gray-700 hover:text-blue-600 transition-colors duration-300 hover:scale-110 absolute right-10"
                        target="_blank"
                    >
                        <MdAdminPanelSettings size={28} />
                    </Link>
                </div>

                
                
                {/* Mobile Nav Links */}
                <AnimatePresence>
                    {isNavOpen && (
                        <motion.div
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={mobileMenuVariants}
                            className="absolute top-20 left-0 right-0 w-full bg-white bg-opacity-98 flex-col gap-3 z-20 shadow-lg rounded-b-2xl border-t overflow-hidden"
                        >
                            <div className="py-6 px-5">
                                <ul className="space-y-1 mb-6">
                                    {tabData.map((tab, index) => (
                                        <motion.li 
                                            key={index}
                                            variants={mobileMenuItemVariants}
                                        >
                                            {tab.dropdown ? (
                                                <div>
                                                    <button
                                                        onClick={() => toggleDropdown(tab.label)}
                                                        className="w-full flex items-center justify-between py-3 px-4 font-medium text-left text-gray-800 hover:text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
                                                    >
                                                        {tab.label}
                                                        <motion.div
                                                            animate={{ rotate: dropdownOpen === tab.label ? 180 : 0 }}
                                                            transition={{ duration: 0.3 }}
                                                        >
                                                            <FaChevronDown className="text-xs" />
                                                        </motion.div>
                                                    </button>
                                                    
                                                    <AnimatePresence>
                                                        {dropdownOpen === tab.label && (
                                                            <motion.div
                                                                initial="closed"
                                                                animate="open"
                                                                exit="closed"
                                                                variants={dropdownVariants}
                                                                className="pl-4 ml-4 border-l border-gray-200 mt-1 space-y-1"
                                                            >
                                                                {tab.dropdown.map((item, idx) => (
                                                                    <Link
                                                                        key={idx}
                                                                        to={item.link}
                                                                        className="block py-2 px-4 text-gray-700 hover:text-blue-600 rounded-lg transition-colors"
                                                                        onClick={() => {
                                                                            onClickHandler(tab.label);
                                                                            setIsNavOpen(false);
                                                                        }}
                                                                    >
                                                                        {item.label}
                                                                    </Link>
                                                                ))}
                                                            </motion.div>
                                                        )}
                                                    </AnimatePresence>
                                                </div>
                                            ) : (
                                                <Link
                                                    to={tab.link}
                                                    className={`block py-3 px-4 rounded-lg font-medium transition-all ${
                                                        activeTab === tab.label
                                                            ? "text-blue-600 bg-blue-50"
                                                            : "text-gray-800 hover:text-blue-600 hover:bg-blue-50"
                                                    }`}
                                                    onClick={() => {
                                                        onClickHandler(tab.label);
                                                        setIsNavOpen(false);
                                                    }}
                                                >
                                                    {tab.label}
                                                </Link>
                                            )}
                                        </motion.li>
                                    ))}
                                </ul>
                                
                                <motion.div variants={mobileMenuItemVariants}>
                                    <Link 
                                        to="/contact-us" 
                                        className="block w-full text-center bg-gradient-to-r from-blue-600 to-indigo-600 text-white py-3 px-6 rounded-lg font-medium shadow-md"
                                        onClick={() => {
                                            onClickHandler("Contact Us");
                                            setIsNavOpen(false);
                                        }}
                                    >
                                        Get Started
                                    </Link>
                                </motion.div>
                                
                                <motion.div 
                                    variants={mobileMenuItemVariants}
                                    className="flex justify-center gap-x-6 pt-6 mt-6 border-t border-gray-100"
                                >
                                    {socialLinks.map((link, index) => (
                                        <a
                                            key={index}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={link.url}
                                            aria-label={link.label}
                                            className="text-gray-700 hover:text-blue-600 text-xl transition-colors"
                                        >
                                            {link.icon}
                                        </a>
                                    ))}
                                </motion.div>
                               <div className="flex justify-center gap-x-6 pt-6 mt-6 border-t border-gray-100">
                               <Link 
                                    to="https://api.qubitware.in/admin" 
                                    target="_blank"
                                    className="ml-3 text-gray-700 hover:text-blue-600 transition-colors duration-300 hover:scale-110"
                                >
                                    <MdAdminPanelSettings size={20} />
                                </Link>
                               </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </section>
    );
}
