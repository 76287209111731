import React, { useEffect, useState } from "react";
import GradientText from "../components/GradientText.component";
import Footer from "../components/Footer.component";
import ServiceCard from "../components/ServiceCard.component.";
import { Navigate, Outlet, Link } from "react-router-dom";
import Service from "./Service.page";
import GradientContactCard from "../components/GradientContactCard.component";
import ImageBanner from "../components/ImageSlider.component";
import { motion } from "framer-motion";

export const allServicesList = [
            {
                "id": 1,
                "images_list": [
                    "1.png",
                    "1b.png",
                    "1c.png",
                    "1d.png"
                ],
                "title": "Microsoft 365 Deployment and Management",
                "description": "We provide comprehensive deployment, migration, and management services for Microsoft 365, ensuring seamless integration with your existing infrastructure. Our team ensures your organization leverages the full potential of Microsoft 365's productivity and collaboration tools.",
                "short_description": "We provide comprehensive deployment, migration, and management services for Microsoft 365, ensuring seamless integration with your existing infrastructure.",
                "highlighting_text": "Maximize the benefits of Microsoft 365 with our expert deployment and management services.",
                "service_features": [
                    "Deployment Planning: Develop a strategy for seamless Microsoft 365 deployment and migration.",
                    "Configuration: Configure Microsoft 365 services to align with your business needs.",
                    "User Training: Provide training to ensure effective use of Microsoft 365 applications.",
                    "Integration: Integrate Microsoft 365 with existing systems and workflows.",
                    "Support: Offer ongoing technical support and troubleshooting.",
                    "Optimization: Continuously monitor and optimize the Microsoft 365 environment for performance and security."
                ],
                "why_service": "Our comprehensive Microsoft 365 services ensure smooth deployment, effective management, and optimized use of your Microsoft 365 environment."
            },
            {
                "id": 2,
                "images_list": [
                    "2.png",
                    "2b.png",
                    "2c.png"
                ],
                "title": "Azure Cloud Services",
                "description": "Our Azure Cloud Services include setup, migration, and ongoing management of your cloud infrastructure. We help businesses harness the power of Azure to improve scalability, reduce costs, and enhance security.",
                "short_description": "Our Azure cloud services include setup, migration, and ongoing management of your cloud infrastructure, helping you harness the power of Azure to improve scalability, reduce costs, and enhance security.",
                "highlighting_text": "Leverage Azure's capabilities for scalable, cost-effective, and secure cloud solutions with our expert services.",
                "service_features": [
                    "Cloud Setup: Assist with the initial setup of your Azure cloud environment.",
                    "Migration Services: Migrate existing applications and data to Azure with minimal disruption.",
                    "Infrastructure Management: Manage and optimize cloud infrastructure for performance and cost efficiency.",
                    "Security Implementation: Implement security measures to protect your cloud resources.",
                    "Scalability Solutions: Design scalable solutions to accommodate growth and changing needs.",
                    "Continuous Monitoring: Monitor and maintain cloud performance to ensure reliability and security."
                ],
                "why_service": "Our Azure cloud services provide expert management and optimization to help you fully leverage Azure's power while ensuring scalability, security, and cost efficiency."
            },
            {
                "id": 3,
                "images_list": [
                    "3.png",
                    "3b.png",
                ],
                "title": "Security Solutions and Compliance",
                "description": "Protect your business with our advanced security solutions. We offer security assessments, implementation of Microsoft security products, and continuous monitoring to ensure your data and systems are safe from threats. We also ensure your organization meets industry compliance standards.",
                "short_description": "Protect your business with our advanced security solutions. We offer security assessments, implementation of Microsoft security products, and continuous monitoring to ensure your data and systems are secure.",
                "highlighting_text": "Secure your business with our comprehensive security solutions and compliance services.",
                "service_features": [
                    "Security Assessments: Conduct thorough assessments to identify vulnerabilities and risks.",
                    "Microsoft Security Products: Implement and configure Microsoft security solutions to safeguard your environment.",
                    "Continuous Monitoring: Monitor systems continuously to detect and respond to threats.",
                    "Compliance Management: Ensure adherence to industry regulations and standards.",
                    "Incident Response: Provide strategies and support for effective incident response.",
                    "Security Policies: Develop and enforce security policies and procedures to protect data."
                ],
                "why_service": "Our advanced security solutions and compliance services safeguard your business against threats and ensure regulatory adherence, providing peace of mind."
            },
            {
                "id": 4,
                "images_list": [
                    "4.png",
                    "4b.png",
                ],
                "title": "Managed IT Services",
                "description": "Let us handle your IT needs with our managed services. From network management to helpdesk support, we provide proactive maintenance and support to keep your IT infrastructure running smoothly.",
                "short_description": "Let us handle your IT needs with our managed services, ensuring seamless IT operations and support.",
                "highlighting_text": "Streamline your IT operations with our comprehensive managed services, designed for reliability and efficiency.",
                "service_features": [
                    "24/7 Monitoring: Provide round-the-clock monitoring and support for your IT infrastructure.",
                    "Proactive Maintenance: Perform routine maintenance to prevent issues and ensure optimal performance.",
                    "Help Desk Support: Offer responsive help desk support for user and technical issues.",
                    "IT Strategy: Develop IT strategies aligned with your business goals and growth plans.",
                    "Security Management: Manage and implement security measures to protect your IT environment.",
                    "System Upgrades: Handle system upgrades and updates to keep your IT infrastructure current."
                ],
                "why_service": "Our managed IT services deliver reliable support and proactive management, ensuring seamless IT operations and allowing you to focus on your core business activities."
            },
            {
                "id": 5,
                "images_list": [
                    "5.png",
                    "5b.png",
                    "5c.png",
                    "5d.png"
                ],
                "title": "Device Management",
                "description": "Ensure seamless and secure management of all your organization's devices with our device management services. We provide comprehensive solutions for provisioning, monitoring, and securing desktops, laptops, tablets, and mobile devices, ensuring they operate efficiently and are protected from threats.",
                "short_description": "Ensure seamless and secure management of all your devices with our comprehensive device management services.",
                "highlighting_text": "Optimize device performance and security with our expert device management solutions.",
                "service_features": [
                    "Device Enrollment: Set up and enroll devices into your management system.",
                    "Configuration Management: Configure device settings to meet organizational policies and requirements.",
                    "Security Policies: Implement security measures to protect devices from threats.",
                    "Remote Management: Provide remote management and support for device issues.",
                    "Inventory Management: Track and manage device inventory for effective asset management.",
                    "Update Management: Handle updates and patches to ensure devices remain secure and up-to-date."
                ],
                "why_service": "Our device management solutions ensure that all your devices are secure, properly configured, and efficiently managed, enhancing overall productivity."
            },
            {
                "id": 6,
                "images_list": [
                    "6.png",
                    "6a.png",
                    "6b.png"
                ],
                "title": "Virtual Desktop Infrastructure (VDI) Solutions",
                "description": "Enhance flexibility and security with our VDI solutions. We design and implement VDI environments that allow your employees to access their desktop and applications from anywhere, on any device. Our VDI services improve resource utilization, reduce costs, and provide a secure, consistent user experience.",
                "short_description": "Enhance flexibility and security with our VDI solutions, offering virtual desktops that provide seamless access and management.",
                "highlighting_text": "Boost flexibility and security with our tailored Virtual Desktop Infrastructure solutions.",
                "service_features": [
                    "VDI Setup: Design and implement a VDI environment tailored to your business needs.",
                    "Remote Access: Enable secure remote access to virtual desktops from any location.",
                    "Performance Optimization: Optimize VDI performance for smooth and efficient user experience.",
                    "Security Measures: Implement security protocols to protect virtual desktops and data.",
                    "User Management: Manage user access and permissions for virtual desktops.",
                    "Maintenance and Support: Provide ongoing maintenance and support for the VDI environment."
                ],
                "why_service": "Our VDI solutions offer a secure and flexible desktop environment, enhancing productivity and accessibility while maintaining robust security."
            },
            {
                "id": 7,
                "images_list": [
                    "7.png",
                    "7b.png",
                    "7c.png"
                ],
                "title": "Microsoft Teams Consulting",
                "description": "Enhance collaboration with our Microsoft Teams consulting services. We assist in deployment, customization, and training to ensure your team makes the most of this powerful communication tool.",
                "short_description": "Enhance collaboration with our Microsoft Teams consulting services. We assist in deployment, customization, and training to ensure your team makes the most of this powerful communication tool.",
                "highlighting_text": "Optimize collaboration with expert Microsoft Teams consulting for deployment, customization, and training.",
                "service_features": [
                    "Deployment: Assist with the setup and deployment of Microsoft Teams in your organization.",
                    "Customization: Tailor Microsoft Teams to fit your specific business needs and workflows.",
                    "Training: Provide training to ensure effective use of Microsoft Teams features and capabilities.",
                    "Integration: Integrate Microsoft Teams with other tools and systems for a seamless experience.",
                    "Support: Offer ongoing support to address any issues and optimize performance.",
                    "Best Practices: Share best practices for using Microsoft Teams to enhance collaboration and productivity."
                ],
                "why_service": "Our consulting services ensure that Microsoft Teams is effectively deployed and utilized, enhancing team collaboration and communication."
            },
            {
                "id": 8,
                "images_list": [
                    "8.png",
                    "8b.png",
                    "8c.png"
                ],
                "title": "Data Backup and Recovery",
                "description": "Safeguard your critical data with our backup and recovery solutions. We offer reliable backup strategies and swift recovery options to minimize downtime in the event of data loss or system failures.",
                "short_description": "Safeguard your critical data with our backup and recovery solutions. We offer reliable backup strategies and swift recovery options to minimize downtime in the event of data loss or system failures.",
                "highlighting_text": "Protect your data with our reliable backup and recovery solutions to ensure minimal downtime and quick recovery.",
                "service_features": [
                    "Backup Strategies: Develop and implement effective backup strategies to safeguard data.",
                    "Automated Backups: Set up automated backup processes to ensure regular and reliable data protection.",
                    "Disaster Recovery: Provide swift recovery options in case of data loss or system failures.",
                    "Data Restoration: Restore data efficiently to minimize business disruption.",
                    "Secure Storage: Ensure secure storage of backup data to protect against unauthorized access.",
                    "Regular Testing: Perform regular testing of backup and recovery procedures to ensure reliability."
                ],
                "why_service": "Our backup and recovery solutions provide robust data protection and quick recovery options, minimizing downtime and safeguarding your critical information."
            },
            {
                "title": "IT Support and Helpdesk Services",
                "description": "Our dedicated support team is available to assist with any IT issues, providing fast and effective resolutions. From troubleshooting software problems to hardware repairs, we ensure minimal disruption to your business operations."
            },
            {
                "id": 10,
                "images_list": [
                    "10.png",
                    "10a.png",
                ],
                "title": "Training and Workshops",
                "description": "Empower your staff with our customized training sessions and workshops. We offer training on Microsoft products, cybersecurity best practices, and productivity tools to enhance your team's skills and efficiency.",
                "short_description": "Empower your staff with our customized training sessions and workshops. We offer training on Microsoft products, cybersecurity best practices, and productivity tools to enhance your team's skills and efficiency.",
                "highlighting_text": "Boost your team's skills and productivity with our tailored training sessions and workshops.",
                "service_features": [
                    "Microsoft Products Training: In-depth training on Microsoft 365, Azure, SharePoint, and Power BI.",
                    "Cybersecurity Awareness: Educate employees on cybersecurity threats, best practices, and incident response.",
                    "Productivity Tools: Train on time management, collaboration tools, and automation techniques.",
                    "Customized Workshops: Design and deliver workshops based on specific organizational needs.",
                    "Interactive Learning: Use hands-on exercises and simulations for effective learning.",
                    "Ongoing Support: Provide continued support and resources to reinforce learning."
                ],
                "why_service": "Our training and workshops enhance your team's skills and efficiency, empowering them with the knowledge needed to excel in their roles and boost overall productivity."
            },
            {
                "id": 11,
                "images_list": [
                    "11.png",
                    "11b.png",
                ],
                "title": "Network Design and Implementation",
                "description": "Our experts design and implement robust and scalable network solutions tailored to your business needs. We ensure optimal performance, reliability, and security for your network infrastructure."
            },                          
            {
                "id": 13,
                "images_list": [
                    "13.png",
                    "13b.png",
                    "13c.png",
                    "13d.png"
                ],
                "title": "Software Licensing and Asset Management",
                "description": "Manage your software assets efficiently with our licensing and asset management services. We help you stay compliant, optimize license usage, and reduce software costs.",
                "short_description": "Manage your software assets efficiently with our licensing and asset management services. We help you stay compliant, optimize license usage, and reduce software costs.",
                "highlighting_text": "Ensure compliance and maximize cost savings with our expert software management solutions.",
                "service_features": [
                    "License Compliance: Verify and maintain compliance with software licensing agreements.",
                    "Usage Optimization: Analyze and optimize license usage to reduce unnecessary costs.",
                    "Asset Tracking: Track and manage all software assets and their lifecycle.",
                    "Cost Management: Implement strategies to minimize software expenses.",
                    "Vendor Management: Manage relationships with software vendors for better terms and support.",
                    "Regular Audits: Conduct periodic audits to ensure ongoing compliance and efficiency."
                ],
                "why_service": "Our comprehensive approach guarantees effective management of software assets, ensuring compliance and reducing overall costs."
            },
            {
                "id": 14,
                "images_list": [
                    "14.png",
                    "14b.png",
                    "14-c.png"
                ],
                "title": "Cybersecurity Training and Awareness",
                "description": "Educate your employees on the latest cybersecurity threats and best practices. Our training programs are designed to create a security-conscious culture within your organization.",
                "short_description": "Educate your employees on the latest cybersecurity threats and best practices. Our training programs create a security-conscious culture within your organization.",
                "highlighting_text": "Build a robust security culture with our tailored cybersecurity training solutions.",
                "service_features": [
                    "Threat Awareness: Train employees to recognize and respond to common cybersecurity threats.",
                    "Best Practices: Teach industry best practices for data protection and secure behavior.",
                    "Incident Response: Equip staff with skills to handle and report security incidents.",
                    "Data Protection: Educate on secure data handling and encryption techniques.",
                    "Regular Updates: Keep employees informed about the latest threats and updates.",
                    "Interactive Training: Utilize hands-on simulations and real-life scenarios for effective learning."
                ],
                "why_service": "Our training programs enhance your team's ability to prevent and respond to security threats, fostering a safer organizational environment."
            },
            {
                "id": 15,
                "images_list": [
                    "15.png",
                    "15b.png",
                    "15c.png"
                ],
                "title": "Full Stack Development",
                "description": "Our team of experienced developers offers end-to-end full stack development services, including frontend and backend development, database management, and API integration. We build scalable, robust, and high-performance web and mobile applications tailored to your business needs.",
                "short_description": "Our experienced developers offer end-to-end full stack development services, including frontend and backend development, database management, and API integration.",
                "highlighting_text": "Build scalable and robust applications with our comprehensive full stack development services.",
                "service_features": [
                    "Frontend Development: Create user-friendly and responsive interfaces using modern technologies.",
                    "Backend Development: Develop robust server-side logic and database integration.",
                    "Database Management: Design and manage databases to ensure efficient data handling.",
                    "API Integration: Connect with third-party services through secure and efficient APIs.",
                    "Scalability: Build solutions that scale with your business growth and demand.",
                    "Quality Assurance: Implement rigorous testing to ensure application reliability and performance."
                ],
                "why_service": "Our full stack development services deliver high-quality, scalable solutions that meet your business needs from start to finish."
            },
            {
                "id": 17,
                "images_list": [
                    "17.png",
                    "17b.png",
                    "17c.png"
                ],
                "title": "Data Science and Analytics",
                "description": "Transform your data into actionable insights with our data science and analytics services. We offer data mining, statistical analysis, and data visualization solutions to help you make data-driven decisions.",
                "short_description": "Transform your data into actionable insights with our data science and analytics services.",
                "highlighting_text": "Unlock the potential of your data to drive strategic decisions with our advanced analytics solutions.",
                "service_features": [
                    "Data Analysis: Analyze data to uncover trends and insights for informed decision-making.",
                    "Predictive Modeling: Use statistical models to forecast future outcomes and trends.",
                    "Data Visualization: Create intuitive visualizations to present complex data clearly.",
                    "Machine Learning: Implement machine learning algorithms to enhance data processing.",
                    "Data Integration: Combine data from multiple sources for comprehensive analysis.",
                    "Report Generation: Generate detailed reports to communicate findings effectively."
                ],
                "why_service": "Our data science and analytics expertise enables you to harness the power of your data for strategic insights and better business outcomes."
            },
            {
                "id": 18,
                "images_list": [
                    "18.png",
                    "18b.png",
                    "18c.png"
                ],
                "title": "Chatbot Development",
                "description": "Enhance customer engagement with our intelligent chatbot solutions. We design and develop AI-powered chatbots that can handle customer queries, provide support, and improve user experience on your website or app.",
                "short_description": "Enhance customer engagement with our intelligent chatbot solutions. We design and develop AI-powered chatbots tailored to your business needs.",
                "highlighting_text": "Improve customer interactions and operational efficiency with our advanced chatbot solutions.",
                "service_features": [
                    "Custom Design: Create chatbots tailored to your specific business requirements and use cases.",
                    "Natural Language Processing: Implement NLP for understanding and responding to user queries.",
                    "Integration: Seamlessly integrate chatbots with existing systems and platforms.",
                    "Automation: Automate routine tasks and inquiries to improve efficiency.",
                    "User Experience: Design chatbots to provide a smooth and engaging user experience.",
                    "Analytics and Reporting: Track chatbot performance and gather insights for continuous improvement."
                ],
                "why_service": "Our AI-powered chatbots enhance customer engagement and streamline operations, delivering tailored solutions that drive business success."
            },
            {
                "id": 19,
                "images_list": [
                    "19.png",
                    "19b.png",
                    "19c.png"
                ],
                "title": "Business Intelligence Solutions",
                "description": "Make informed business decisions with our business intelligence solutions. We implement BI tools that gather, analyze, and visualize data, providing you with comprehensive insights into your business performance.",
                "short_description": "Make informed business decisions with our business intelligence solutions. We implement BI tools that gather, analyze, and visualize data, providing comprehensive insights.",
                "highlighting_text": "Leverage advanced BI tools to turn data into actionable insights and drive strategic decisions.",
                "service_features": [
                    "Data Integration: Combine data from various sources for a unified view.",
                    "Analytics: Analyze data to identify trends and gain insights.",
                    "Visualization: Create visual representations of data for easy interpretation.",
                    "Dashboard Development: Develop interactive dashboards for real-time data monitoring.",
                    "Reporting: Generate detailed reports to support decision-making processes.",
                    "Performance Metrics: Track key performance indicators to measure business performance."
                ],
                "why_service": "Our business intelligence solutions provide valuable insights through data analysis and visualization, empowering you to make informed and strategic decisions."
            },
            {
                "id": 20,
                "images_list": [
                    "20.png",
                    "20b.png",
                    "20c.png"
                ],
                "title": "Robotic Process Automation (RPA)",
                "description": "Automate repetitive tasks and streamline operations with our RPA services. We deploy bots that can handle routine tasks, freeing up your staff to focus on more strategic activities.",
                "short_description": "Automate repetitive tasks and streamline operations with our RPA services. We deploy bots to handle routine tasks, freeing up your staff to focus on more strategic activities.",
                "highlighting_text": "Enhance efficiency by automating repetitive tasks with our intelligent RPA solutions.",
                "service_features": [
                    "Bot Development: Design and develop custom bots to automate specific tasks.",
                    "Process Analysis: Analyze workflows to identify opportunities for automation.",
                    "Integration: Integrate RPA solutions with existing systems and processes.",
                    "Deployment: Deploy bots and manage their operation within your environment.",
                    "Monitoring: Monitor bot performance and efficiency to ensure optimal operation.",
                    "Support: Provide ongoing support and maintenance for RPA solutions."
                ],
                "why_service": "Our RPA services streamline operations and boost productivity by automating repetitive tasks, allowing your team to focus on higher-value activities."
            },
            {
                "id": 21,
                "images_list": [
                    "21.png",
                    "21b.png",
                    "21c.png"
                ],
                "title": "Natural Language Processing (NLP)",
                "description": "Utilize our NLP expertise to extract meaningful information from unstructured data. Our NLP solutions include text analysis, sentiment analysis, and language translation services.",
                "short_description": "Utilize our NLP expertise to extract meaningful information from unstructured data. Our NLP solutions include text analysis, sentiment analysis, and language translation services.",
                "highlighting_text": "Unlock valuable insights from unstructured data with our advanced NLP solutions.",
                "service_features": [
                    "Text Analysis: Extract key information and insights from textual data.",
                    "Sentiment Analysis: Analyze sentiment in text to gauge opinions and emotions.",
                    "Language Translation: Translate text across multiple languages for broader communication.",
                    "Named Entity Recognition: Identify and categorize entities within text for better understanding.",
                    "Speech Recognition: Convert spoken language into text for analysis and action.",
                    "Custom NLP Models: Develop tailored NLP models to meet specific business needs."
                ],
                "why_service": "Our NLP solutions enable you to derive actionable insights from unstructured data, enhancing decision-making and communication."
            },
            {
                "id": 22,
                "images_list": [
                    "22.png",
                    "22b.png",
                    "22c.png"
                ],
                "title": "Computer Vision Solutions",
                "description": "Implement advanced computer vision technologies to enhance your business processes. Our solutions include image recognition, object detection, and video analytics for various applications.",
                "short_description": "Implement advanced computer vision technologies to enhance your business processes. Our solutions include image recognition, object detection, and video analytics.",
                "highlighting_text": "Transform your processes with cutting-edge computer vision technologies for improved efficiency and accuracy.",
                "service_features": [
                    "Image Recognition: Identify and classify objects within images for various applications.",
                    "Object Detection: Detect and locate objects in images and videos.",
                    "Video Analytics: Analyze video data for insights and automated actions.",
                    "Facial Recognition: Implement facial recognition technology for security and identification.",
                    "Pattern Recognition: Detect patterns and anomalies in visual data for analysis.",
                    "Custom Solutions: Develop tailored computer vision solutions to address specific business needs."
                ],
                "why_service": "Our computer vision solutions enhance your business processes with advanced image and video analysis, providing accuracy and efficiency."
            },
]

const findServiceByTitleLoader = ({ params }) => {
    return allServicesList.find((service) => service.title === params.service_title);
}

export const services_routes = [
    {
        path : '',
        element : <AllServices/>,
    },
    {
        path: ':service_title',
        element : <Service/>,
        loader : findServiceByTitleLoader
    },
    {
        path: '*',
        element: <Navigate to="/not-found" />,
    }
];

export default function Services() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "AI & IT Services | QUBITWARE"
    }, []);

    return (
        <>
            <Outlet/>
            <Footer varient="blue" />
        </>
    );
}

// Organized service categories for better presentation
const serviceCategories = [
    {
        title: "AI & Machine Learning",
        description: "Harness the power of artificial intelligence to transform your business operations",
        services: [15, 17, 18, 19, 20, 21, 22] // IDs from allServicesList
    },
    {
        title: "Cloud & Infrastructure",
        description: "Build scalable, secure and reliable IT infrastructure for your organization",
        services: [1, 2, 4, 6, 8, 11]
    },
    {
        title: "Security & Compliance",
        description: "Protect your business with advanced security solutions and ensure regulatory compliance",
        services: [3, 5, 14]
    },
    {
        title: "Business Solutions",
        description: "Streamline operations and boost productivity with our tailored business services",
        services: [7, 10, 13]
    }
];

// Testimonials data
const testimonials = [
    
];

// FAQ data
const faqs = [
    {
        question: "How can AI solutions benefit my business?",
        answer: "AI solutions can automate repetitive tasks, provide data-driven insights, enhance customer experiences, improve decision-making, and create new business opportunities. Our AI implementations typically result in 30-50% efficiency improvements in targeted processes."
    },
    {
        question: "What is the typical timeline for implementing a new IT system?",
        answer: "Implementation timelines vary based on project scope, complexity, and your organization's specific needs. Small projects typically take 4-6 weeks, while enterprise-wide implementations may take 3-6 months. We provide detailed timelines during our initial consultation."
    },
    {
        question: "How do you ensure the security of our data during cloud migrations?",
        answer: "We employ a comprehensive security approach including encryption, secure transfer protocols, access controls, and continuous monitoring. Our team follows industry best practices and compliance standards like GDPR, HIPAA, and SOC 2 to ensure your data remains protected throughout the migration process."
    },
    {
        question: "What ongoing support do you provide after project completion?",
        answer: "We offer various support tiers including 24/7 technical support, regular maintenance, performance monitoring, security updates, and staff training. Our goal is to ensure your systems continue to operate optimally and adapt to your evolving business needs."
    }
];

function AllServices() {
    const [activeTestimonial, setActiveTestimonial] = useState(0);
    const [activeFaq, setActiveFaq] = useState(null);

    const generateBannerImagesList = () => {
        const imagesList = [];
        allServicesList.forEach((service) => {
            if (service.images_list && service.images_list.length > 0) {
                imagesList.push(`/service_imgs/${service.id}.png`);
            }
        });
        return imagesList;
    };

    // Get services by category
    const getServicesByCategory = (categoryServices) => {
        return categoryServices.map(id => 
            allServicesList.find(service => service.id === id)
        ).filter(service => service); // Filter out any undefined services
    };

    return (
        <div className="flex flex-col items-center">
            {/* Hero Section */}
            <section className="w-full bg-gradient-to-r from-blue-900 via-indigo-800 to-purple-900 text-white">
                <div className="container mx-auto py-20 px-4 flex flex-col items-center text-center">
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                        className="max-w-4xl"
                    >
                        <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold mb-6">
                            Transforming Business Through <GradientText className="font-extrabold">Intelligent Technology</GradientText>
                        </h1>
                        <p className="text-xl md:text-2xl mb-10 text-gray-200">
                            Harness the power of AI and cutting-edge IT solutions to drive innovation, 
                            efficiency, and growth for your organization
                        </p>
                        <div className="flex flex-col sm:flex-row gap-4 justify-center">
                            <Link to="/contact-us" className="bg-white text-blue-900 hover:bg-gray-100 px-8 py-4 rounded-lg font-bold text-lg transition-all">
                                Schedule Consultation
                            </Link>
                            <Link to="#services-overview" className="bg-transparent border-2 border-white hover:bg-white/10 px-8 py-4 rounded-lg font-bold text-lg transition-all">
                                Explore Services
                            </Link>
                        </div>
                    </motion.div>
                </div>
                <div className="w-full h-24 bg-white rounded-t-[50%] -mb-1"></div>
            </section>

            {/* Services Overview */}
            <section id="services-overview" className="w-full py-16 bg-white">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-16">
                        <h2 className="text-4xl font-bold mb-4">Our <GradientText>Elite Solutions</GradientText></h2>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            We deliver comprehensive technology services designed to transform your business operations,
                            enhance productivity, and drive sustainable growth
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        {serviceCategories.map((category, index) => (
                            <motion.div 
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                                viewport={{ once: true }}
                                className="bg-gray-50 rounded-xl p-8 shadow-lg hover:shadow-xl transition-all"
                            >
                                <h3 className="text-2xl font-bold mb-3">{category.title}</h3>
                                <p className="text-gray-600 mb-6">{category.description}</p>
                                <Link to={`#category-${index}`} className="text-blue-600 font-semibold hover:text-blue-800">
                                    Explore services →
                                </Link>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Stats Section */}
            <section className="w-full py-20 bg-gray-100">
                <div className="container mx-auto px-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 text-center">
                        <motion.div 
                            initial={{ opacity: 0, scale: 0.9 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5 }}
                            viewport={{ once: true }}
                            className="bg-white p-8 rounded-xl shadow-md"
                        >
                            <h3 className="text-5xl font-bold text-blue-600 mb-2">98%</h3>
                            <p className="text-gray-700">Client Satisfaction Rate</p>
                        </motion.div>
                        <motion.div 
                            initial={{ opacity: 0, scale: 0.9 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5, delay: 0.1 }}
                            viewport={{ once: true }}
                            className="bg-white p-8 rounded-xl shadow-md"
                        >
                            <h3 className="text-5xl font-bold text-blue-600 mb-2">5+</h3>
                            <p className="text-gray-700">Projects Completed</p>
                        </motion.div>
                        <motion.div 
                            initial={{ opacity: 0, scale: 0.9 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            viewport={{ once: true }}
                            className="bg-white p-8 rounded-xl shadow-md"
                        >
                            <h3 className="text-5xl font-bold text-blue-600 mb-2">40%</h3>
                            <p className="text-gray-700">Avg. Efficiency Improvement</p>
                        </motion.div>
                        <motion.div 
                            initial={{ opacity: 0, scale: 0.9 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            viewport={{ once: true }}
                            className="bg-white p-8 rounded-xl shadow-md"
                        >
                            <h3 className="text-5xl font-bold text-blue-600 mb-2">10</h3>
                            <p className="text-gray-700">Years of Experience</p>
                        </motion.div>
                    </div>
                </div>
            </section>

            {/* Detailed Service Categories */}
            {serviceCategories.map((category, categoryIndex) => (
                <section 
                    id={`category-${categoryIndex}`} 
                    key={categoryIndex} 
                    className={`w-full py-16 ${categoryIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
                >
                    <div className="container mx-auto px-4">
                        <div className="text-center mb-12">
                            <GradientText className="text-4xl font-bold mb-4">{category.title}</GradientText>
                            <p className="text-lg text-gray-600 max-w-3xl mx-auto">{category.description}</p>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                            {getServicesByCategory(category.services).map((service, index) => (
                                <motion.div 
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: index * 0.1 }}
                                    viewport={{ once: true }}
                                >
                                    <ServiceCard
                                        title={service.title}
                                        description={service.short_description || service.description}
                                        img_url={`/service_imgs/${service.id}.png`}
                                    />
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </section>
            ))}

            {/* Case Study Section */}
            <section className="w-full py-20 bg-gradient-to-br from-blue-900 to-indigo-900 text-white">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-16">
                        <h2 className="text-4xl font-bold mb-4">Success Stories</h2>
                        <p className="text-xl text-gray-200 max-w-3xl mx-auto">
                            See how our solutions have transformed businesses and delivered measurable results
                        </p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                        <motion.div 
                            initial={{ opacity: 0, x: -30 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.6 }}
                            viewport={{ once: true }}
                            className="bg-white/10 backdrop-blur-sm p-8 rounded-xl"
                        >
                            <div className="mb-6">
                                <span className="bg-blue-600 text-white py-1 px-3 rounded-full text-sm font-medium">AI Analytics</span>
                            </div>
                            <h3 className="text-2xl font-bold mb-4">40% Reduction in Operational Costs</h3>
                            <p className="text-gray-200 mb-6">
                                A manufacturing enterprise implemented our AI-powered analytics solution to optimize production workflows, 
                                resulting in a 40% reduction in operational costs and a 25% increase in production output.
                            </p>
                            <Link to="/contact-us" className="inline-block bg-white text-blue-900 hover:bg-gray-100 px-6 py-2 rounded-lg font-bold transition-all">
                                Get Similar Results
                            </Link>
                        </motion.div>

                        <motion.div 
                            initial={{ opacity: 0, x: 30 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.6 }}
                            viewport={{ once: true }}
                            className="bg-white/10 backdrop-blur-sm p-8 rounded-xl"
                        >
                            <div className="mb-6">
                                <span className="bg-purple-600 text-white py-1 px-3 rounded-full text-sm font-medium">Cloud Migration</span>
                            </div>
                            <h3 className="text-2xl font-bold mb-4">99.9% Uptime & 65% Cost Savings</h3>
                            <p className="text-gray-200 mb-6">
                                A healthcare provider achieved 99.9% uptime and 65% infrastructure cost savings after our team 
                                migrated their legacy systems to a scalable and secure cloud environment with built-in redundancy.
                            </p>
                            <Link to="/contact-us" className="inline-block bg-white text-blue-900 hover:bg-gray-100 px-6 py-2 rounded-lg font-bold transition-all">
                                Get Similar Results
                            </Link>
                        </motion.div>
                    </div>
                </div>
            </section>

            {/* Testimonials Section */}
            {/* <section className="w-full py-20 bg-white">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-16">
                        <GradientText className="text-4xl font-bold mb-4">Client Testimonials</GradientText>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            Don't just take our word for it. Here's what our clients have to say about working with us
                        </p>
                    </div>

                    <div className="relative max-w-4xl mx-auto">
                        {testimonials.map((testimonial, index) => (
                            <motion.div 
                                key={index}
                                initial={{ opacity: 0 }}
                                animate={{ 
                                    opacity: activeTestimonial === index ? 1 : 0,
                                    scale: activeTestimonial === index ? 1 : 0.9,
                                }}
                                transition={{ duration: 0.5 }}
                                className="bg-gray-50 p-8 rounded-xl shadow-lg text-center absolute top-0 left-0 w-full"
                                style={{ display: activeTestimonial === index ? 'block' : 'none' }}
                            >
                                <div className="mb-6">
                                    <img 
                                        src={testimonial.image} 
                                        alt={testimonial.name}
                                        className="w-20 h-20 rounded-full mx-auto object-cover"
                                        onError={(e) => {
                                            e.target.src = "/logo.png"; // Fallback image
                                        }}
                                    />
                                </div>
                                <p className="text-xl italic mb-6">"{testimonial.quote}"</p>
                                <h4 className="text-lg font-bold">{testimonial.name}</h4>
                                <p className="text-gray-600">{testimonial.company}</p>
                            </motion.div>
                        ))}

                        <div className="absolute -bottom-16 left-0 right-0 flex justify-center space-x-2">
                            {testimonials.map((_, index) => (
                                <button
                                    key={index}
                                    onClick={() => setActiveTestimonial(index)}
                                    className={`w-3 h-3 rounded-full ${
                                        activeTestimonial === index ? 'bg-blue-600' : 'bg-gray-300'
                                    }`}
                                    aria-label={`View testimonial ${index + 1}`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </section> */}

            {/* FAQ Section */}
            <section className="w-full py-20 bg-gray-50">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-16">
                        <GradientText className="text-4xl font-bold mb-4">Frequently Asked Questions</GradientText>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            Find answers to common questions about our services and solutions
                        </p>
                    </div>

                    <div className="max-w-3xl mx-auto">
                        {faqs.map((faq, index) => (
                            <motion.div 
                                key={index}
                                initial={{ opacity: 0, y: 10 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                                viewport={{ once: true }}
                                className="mb-4"
                            >
                                <button
                                    onClick={() => setActiveFaq(activeFaq === index ? null : index)}
                                    className="flex justify-between items-center w-full p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-all"
                                >
                                    <span className="text-lg font-semibold text-left">{faq.question}</span>
                                    <span className="text-2xl">
                                        {activeFaq === index ? '−' : '+'}
                                    </span>
                                </button>
                                {activeFaq === index && (
                                    <motion.div 
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: 'auto' }}
                                        exit={{ opacity: 0, height: 0 }}
                                        transition={{ duration: 0.3 }}
                                        className="p-6 bg-white rounded-b-lg shadow-md mt-1"
                                    >
                                        <p className="text-gray-700">{faq.answer}</p>
                                    </motion.div>
                                )}
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className="w-full py-20 bg-white">
                <div className="container mx-auto px-4">
                    <GradientContactCard message={"Ready to transform your business with intelligent technology solutions?"} />
                </div>
            </section>
        </div>
    );
}
